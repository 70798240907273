// 第二層 ROUTER
// 系統管理
let KendoTest = {
  path: '/KendoTest',
  redirect: '/KendoTest/DropDownList',
  component: () => import('@/views/OltFrame.vue'),
  children: [
    {
      path: 'DropDownList',
      name: 'DropDownList',
      component: () =>
        import('@/views/php/KendoTest/DropDownList/PageRecord.vue')
    },
    {
      path: 'ListBox',
      name: 'ListBox',
      component: () => import('@/views/php/KendoTest/ListBox/PageRecord.vue')
    },
    {
      path: 'TabStrip',
      name: 'TabStrip',
      component: () => import('@/views/php/KendoTest/TabStrip/PageRecord.vue')
    },
    {
      path: 'Form',
      name: 'Form',
      component: () => import('@/views/php/KendoTest/Form/PageRecord.vue')
    },
    {
      path: 'TestMail',
      name: 'TestMail',
      component: () => import('@/views/php/KendoTest/TestMail/PageRecord.vue')
    }
 
  ]
};

export default KendoTest;
