/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  locationUrl: oltEncrypt.storeDecodeObject( sessionStorage.getItem('locationUrl')) ,
  pathQuery: oltEncrypt.storeDecodeObject( sessionStorage.getItem('pathQuery')) ,
  //clickHistory: oltEncrypt.storeDecodeText( sessionStorage.getItem('clickHistory'))
  //locationUrl: sessionStorage.getItem('urlObj') ,
};

/*
mutations 必須是同步執行，而 actions 則可以異步執行。
*/
export const actions = {
  // 使用 Action 寫入 location URl
  locationUrl({ commit }, urlObj) {
    //console.log('action url : ' , urlObj);
    commit('locationUrl', urlObj);
  },
  cleanLocationUrl({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanLocationUrl');
  },
  setupPathQuery({ commit }, urlObj) {
    //console.log('action url : ' , urlObj);
    commit('setupPathQuery', urlObj);
  },
  cleanPathQuery({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanPathQuery');
  },
  setupClickHistory({ commit }, urlObj) {
    //console.log('action url : ' , urlObj);
    commit('setupClickHistory', urlObj);
  },
  cleanClickHistory({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanClickHistory');
  },

};

/*
Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。

mutation必定是同步函數，沒有例外
mutation是vuex中唯一可以改動state的方法
也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程

發出一個action(dispatch an action)
接收到action後，執行對應的mutation (commit a mutation)
透過mutation更改state
*/
export const mutations = {
  locationUrl(state, urlObj) {
    //state.locationUrl = window.btoa(locationUrl) ;
    sessionStorage.setItem('locationUrl', oltEncrypt.storeEncodeObject(urlObj));
    //sessionStorage.setItem('urlObj', oltEncrypt.storeEncodeObject(urlObj));
    state.locationUrl = urlObj;
  },
  cleanLocationUrl(state) {
    //sessionStorage.removeItem('locationUrl');
    state.locationUrl = null;
  },
  setupPathQuery(state, urlObj) {
    //state.locationUrl = window.btoa(locationUrl) ;
    sessionStorage.setItem('pathQuery', oltEncrypt.storeEncodeObject(urlObj));
    state.pathQuery = urlObj;
  },
  cleanPathQuery(state) {
    state.pathQuery = null;
  },
  setupClickHistory(state, urlObj) {
    //state.locationUrl = window.btoa(locationUrl) ;
    state.clickHistory = urlObj;
  },
  cleanClickHistory(state) {
    state.clickHistory = null;
  }





};

export const getters = {
  //isAuthenticated: state => Boolean(state.user) || Boolean(sessionStorage.getItem('user')),
  isLocationUrl(state) {
    if (state.locationUrl) {
      return true;
    }
    return false;
  },
  isPathQuery(state) {
    if (state.pathQuery) {
      return true;
    }
    return false;
  },
  isClickHistory(state) {
    if (state.clickHistory) {
      return true;
    }
    return false;
  }



};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
