/* eslint-disable camelcase */
// vue set 
const baseUrl = process.env.NODE_ENV === 'production' ? '/' : './' ; 

const dev = {
  showLicense:false ,
  showSideNav:true,
  showApiTitle:false,
  usePHPMenu:true,
  useDotNetMenu:false
};

const config = { 
  restApiDriver: 'phpApiUrl', // default api driver 
  dotnetApiDriver: 'dotnetApiUrl',
  baseUrl,
  pathQueryMode:'router', // get or router
  //pathQueryMode:'get', 
  clientWebUrl : 'https://eze.ntpc.edu.tw',
  downloadTronEduUrl : 'https://cloudraise.com/TronEduExamEditor',
  downloadCRStreamUrl : 'https://cloudraise.com/CRStreamConvert',
};

const defaultYearCode = {
  num: '111',
};

// restApiDriver 
const restApiDriver = {
  defaultApiUrl : 'https://eze.ntpc.edu.tw/AdminApi/api' ,
  dotnetApiUrl  : 'https://eze.ntpc.edu.tw:5080/api' ,
  phpApiUrl  : 'https://eze.ntpc.edu.tw/AdminApi/api' ,
  tiny_mce_wiris : 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
};

const modulesList = {
  ssoSignIn: false,
};

const modulesVerify = {
  uploadVerify: false, //true:上傳機(設定命題僅輸入引用來源可分享)
};

const reviewExamType = {
  type: 'json',
};


const examPaperPrintType = {
  Horizonttial: '橫式',
  Vertical: '直式',
};

const userIconConfig = {
  'domain' : 'eze.ntpc.edu.tw',
  'protocol' : 'https://',
  'upload_path' : '/AdminApi/storage/images/users/icon/',
  'name': 'user_icon',
};

const examPaperFormatList = [
  {
    'id': 1,
    'name':'版型1',
  }
];
const examPaperFormat = {
  format1: '版型1',
};
const ssoConfig = {
  'domain' : 'eze.ntpc.edu.tw',
  'protocol' : 'https://',
  'path' : '/SingleSignOn/',
  'aesKey': 'nhuiasxinyasxinydongbidongbinhui',
  'aesIv': 'tgvfrjmnbyhpoilk',
  'logout': 'logout.php',
};

const instructionalVideosConfig = {
  'domain' : 'eze.ntpc.edu.tw',
  'protocol' : 'http://',
  'file_path' : '/AdminApi/storage/instructional_videos/',
};

const downloadExamPaperConfig = {
  'domain' : 'eze.ntpc.edu.tw',
  'protocol' : 'http://',
  'path' : '/DownloadExamPaper',
  'pathCombine' : '/DownloadExamPaperCombine',
  'aesKey': 'nhuiasxinyasxinydongbidongbinhui',
  'aesIv': 'tgvfrjmnbyhpoilk',
};

export { config, dev , restApiDriver , defaultYearCode, modulesList ,reviewExamType, examPaperPrintType , userIconConfig, examPaperFormat, examPaperFormatList ,ssoConfig, instructionalVideosConfig, downloadExamPaperConfig , modulesVerify};
