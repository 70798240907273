
// 第二層 ROUTER
let TestPolicyMs = {
  path: '/TestPolicyMs',
  redirect: '/TestPolicyMs/TestType',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    { // 考試類型管理
      path: 'TestType',
      name: 'TestType',
      component: () => import('@/views/php/TestPolicyMs/TestType/PageRecord.vue'),
    },
    { // 考試管理
      path: 'TestList',
      name: 'TestList',
      component: () => import('@/views/php/TestPolicyMs/TestList/PageRecord.vue'),
    },
    { // 考試、試卷管理
      path: 'TestExamPaperRt',
      name: 'TestExamPaperRt',
      component: () => import('@/views/php/TestPolicyMs/TestExamPaperRt/PageRecord.vue'),
    },
  ]
};
  
export default TestPolicyMs;
  