<template>
  <router-view />
</template>
<script>
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import { config , ssoConfig} from '@/include/config';
import {
  h,
  ref,
  toRefs,
  reactive,
  watch,
  inject,
  onMounted,
  onBeforeUpdate,
  onUpdated
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  oltConsole,
  oAA,
  oApiError,
  oAuth,
  oAdminFrame
} from '@/lib/oltModule/initAdminVue3';
import moment from 'moment';
//import Header from '@/components/Header.vue';
import { useI18n } from 'vue-i18n';
import { exit } from 'process';

export default {
  name: 'App',
  components: {
  },
  setup(){
    /**
     *  swal start
     */
    const swal = inject('$swal');
    /**
     *  swal end
     */

    /**
     *  i18n Start
     */
    const { t, locale } = useI18n({
      useScope: 'global' // 必需設定 SCOPE 才能覆蓋設定
    });

    locale.value = 'zh-TW';
    /**
     *  i18n end
     */
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    onMounted(async () => {
      //console.log('App onMounted');
      chkLogin();
      initRouter();
    });
    // Reload MDInput End

    onUpdated(async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals 
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    }) ;

    function gotoPage(page){
      if(router.currentRoute.value.name == page) { return; }
      router.push(page);
    }

    function initRouter(){
      //console.log('initRouter');

      //console.log('router : ', router);
      if(router != null && router.options.history.location == '/'){
        gotoPage('AuthPage/SignIn');
      }
    }


    function chkLogin(){

      //console.log('chkLogin store : ',store);

      let isLogin = oAuth.isLogin(store);

      //console.log('chkLogin isLogin : ',isLogin);

      if(!isLogin){
        /*
        swal.fire({
          icon:'warning',
          html:'目前您的狀態為登出狀態<br>請重新登入本系統！！',
          buttonsStyling: true,
          confirmButtonClass: 'md-button md-success',
          confirmButtonText:t('messages.confirm_button'),
        }).then( result => {
          if (result.value) {
            //console.log('result : ' , result);
            resetForm();
            initForm();
            // eslint-disable-next-line no-undef
            oAdminFrame.hideModal('add');
            emit('addCallBack');
          }
        });
        */
        // let query = router.currentRoute.value.query;
        let url = window.location.href;
        // console.log("url => ", url);
        let searchArr = url.split('?');
        // console.log(searchArr);
        let v = '';
        if(searchArr && searchArr.length > 1){
          let queryArr = searchArr[1].split('&');
          queryArr.forEach((item) => {
            if (item.split('=')[0] == 'v') {
              v = item.split('=')[1];
              // console.log('v 的參數:' + item.split('=')[1]);
            } 
          });
        }
        let query = {v:v};

        if(!url.includes('SSOAutoLogin') && !url.includes('SingleSignOn') && !url.includes('SignIn')){
          // router.push({ path: '/AuthPage/SignIn', query: query });
          let goToUrl = `${ssoConfig.protocol}${ssoConfig.domain}/SingleSignOn/autosignin.php`;
          window.location.href = goToUrl;
        }

      }

    }

    //chkLogin();



    


    return {
      // i18n
      t,
      locale,      
      // record page
      //rtAllRecord,
      //pageRecord,
      //recordData,
      //rtPageRecord,
      // Loading
      isLoading:false,
      // const data
      token:null,
      //apiName,
      //pkyKey,
      //tableName1,
      // function 
      gotoPage,
      initRouter,
    };
  },
};
</script>

<style>
.swal2-popup {
  font-size: 0.8rem !important;
}
.swal2-container{
  z-index: 20000;
}
#app .swal2-container{
  z-index: 20000;
}
.swal2-styled.swal2-cancel {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  font-size: 0.75rem !important;
  border-radius: 0.5rem !important;
}

.popup-content {
    padding: 15px !important;
    color: #787878;
    border: 1px solid rgba(0,0,0,.05);
    border-radius: 8px 8px 8px 8px;
}
.sidenav {
  z-index: 1030 !important;
}
.btn-outline-dark {
  color: #4F4F52 !important;
  border-color: #4F4F52 !important;
}
.row-exam {
  display: flex;
  flex-wrap: wrap;
}
</style>
<!-- 平板以下載具 用 Body Scroll 下拉效能會比較好  -->
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<style>

#app .spinner {
  position:relative;
  text-align:center;
  margin:auto;
  padding:0;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #666;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-no-more{
  text-align:center;
  margin:auto;
}
.spinner-no-results{
  text-align:center;
  margin:auto;
}


</style>
<!-- 用不到
<style src="@/assets/css/nucleo-icons.css" />
<style src="@/assets/css/nucleo-svg.css" />
-->
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
