
// 第二層 ROUTER 
// 系統管理
let SysObjectMs = {
  path: '/SysObjectMs',
  redirect: '/SysObjectMs/AdminRoles',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'AdministrativeRegion',
      name: 'AdministrativeRegion',
      component: () => import('@/views/php/SysObjectMs/AdministrativeRegion/PageRecord.vue'),
    },
    {
      path: 'EducationSystem',
      name: 'EducationSystem',
      component: () => import('@/views/php/SysObjectMs/EducationSystem/PageRecord.vue'),
    },
    {
      path: 'Grade',
      name: 'Grade',
      component: () => import('@/views/php/SysObjectMs/Grade/PageRecord.vue'),
    },
    {
      path: 'Edition',
      name: 'Edition',
      component: () => import('@/views/php/SysObjectMs/Edition/PageRecord.vue'),
    },
    {
      path: 'DifficultyLevel',
      name: 'DifficultyLevel',
      component: () => import('@/views/php/SysObjectMs/DifficultyLevel/PageRecord.vue'),
    },
    {
      path: 'Edition',
      name: 'Edition',
      component: () => import('@/views/php/SysObjectMs/Edition/PageRecord.vue'),
    },
    {
      path: 'IssueIntegration',
      name: 'IssueIntegration',
      component: () => import('@/views/php/SysObjectMs/IssueIntegration/PageRecord.vue'),
    },
    {
      path: 'MessageType',
      name: 'MessageType',
      component: () => import('@/views/php/SysObjectMs/MessageType/PageRecord.vue'),
    },
    {
      path: 'MessageCategory',
      name: 'MessageCategory',
      component: () => import('@/views/php/SysObjectMs/MessageCategory/PageRecord.vue'),
    },
    {
      path: 'AcademicYearsGradeSemesterSubjectEditionRt',
      name: 'AcademicYearsGradeSemesterSubjectEditionRt',
      component: () => import('@/views/php/SysObjectMs/AcademicYearsGradeSemesterSubjectEditionRt/PageRecord.vue'),
    },
    {
      path: 'FieldSubjectLearningContentPhaseRt',
      name: 'FieldSubjectLearningContentPhaseRt',
      component: () => import('@/views/php/SysObjectMs/FieldSubjectLearningContentPhaseRt/PageRecord.vue'),
    },
    {
      path: 'EducationSystemGradeLearningPhaseRt',
      name: 'EducationSystemGradeLearningPhaseRt',
      component: () => import('@/views/php/SysObjectMs/EducationSystemGradeLearningPhaseRt/PageRecord.vue'),
    },
    {
      path: 'GradeSemester',
      name: 'GradeSemester',
      component: () => import('@/views/php/SysObjectMs/GradeSemester/PageRecord.vue'),
    },
    {
      path: 'Field',
      name: 'Field',
      component: () => import('@/views/php/SysObjectMs/Field/PageRecord.vue'),
    },
    {
      path: 'GradeSemesterSubject',
      name: 'GradeSemesterSubject',
      component: () => import('@/views/php/SysObjectMs/GradeSemesterSubject/PageRecord.vue'),
    },
    {
      path: 'TaskType',
      name: 'TaskType',
      component: () => import('@/views/php/SysObjectMs/TaskType/PageRecord.vue'),
    },
    {
      path: 'TaskMode',
      name: 'TaskMode',
      component: () => import('@/views/php/SysObjectMs/TaskMode/PageRecord.vue'),
    },
    {
      path: 'AnnouncementType',
      name: 'AnnouncementType',
      component: () => import('@/views/php/SysObjectMs/AnnouncementType/PageRecord.vue'),
    },
    {
      path: 'AnnouncementCategory',
      name: 'AnnouncementCategory',
      component: () => import('@/views/php/SysObjectMs/AnnouncementCategory/PageRecord.vue'),
    },
    {
      path: 'Subject',
      name: 'Subject',
      component: () => import('@/views/php/SysObjectMs/Subject/PageRecord.vue'),
    },
    {
      path: 'LearningPhase',
      name: 'LearningPhase',
      component: () => import('@/views/php/SysObjectMs/LearningPhase/PageRecord.vue'),
    },
    {
      path: 'AnnouncementMode',
      name: 'AnnouncementMode',
      component: () => import('@/views/php/SysObjectMs/AnnouncementMode/PageRecord.vue'),
    },

  ]
};
  
export default SysObjectMs;
  