
// 第二層 ROUTER 
// 系統管理
let SysMs = {
  path: '/SysMs',
  redirect: '/SysMs/AdminRoles',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'AdminRoles',
      name: 'AdminRoles',
      component: () => import('@/views/php/SysMs/AdminRoles/PageRecord.vue'),
    },
    /*
    {
      path: 'MSelect',
      name: 'MSelect',
      component: () => import('@/views/SysMs/AdminUsersJoin/MSelect2.vue'),
    },    
    */
    {
      path: 'AdminUsersJoin',
      name: 'AdminUsersJoin',
      component: () => import('@/views/php/SysMs/AdminUsersJoin/PageRecord.vue'),
    },
    {
      path: 'AdminUsers',
      name: 'AdminUsers',
      component: () => import('@/views/php/SysMs/AdminUsers/PageRecord.vue'),
    },
    {
      path: 'AdminModules',
      name: 'AdminModules',
      component: () => import('@/views/php/SysMs/AdminModules/PageRecord.vue'),
    },
    {
      path: 'AdminFuns',
      name: 'AdminFuns',
      component: () => import('@/views/php/SysMs/AdminFuns/PageRecord.vue'),
    },
    {
      path: 'AdminRoleFunRt',
      name: 'AdminRoleFunRt',
      component: () => import('@/views/php/SysMs/AdminRoleFunRt/PageRecord.vue'),
    },
    {
      path: 'AdminUserRt',
      name: 'AdminUserRt',
      component: () => import('@/views/php/SysMs/AdminUserRt/PageRecord.vue'),
    },
    { // Yves add SBDW5-989
      path: 'AdminRoleFunRtGroups',
      name: 'AdminRoleFunRtGroups',
      component: () => import('@/views/php/SysMs/AdminRoleFunRtGroups/PageRecord.vue'),
    },
    {
      path: 'School',
      name: 'School',
      component: () => import('@/views/php/SysMs/School/PageRecord.vue'),
    },
    {
      path: 'SchoolDistrict',
      name: 'SchoolDistrict',
      component: () => import('@/views/php/SysMs/SchoolDistrict/PageRecord.vue'),
    },
    {
      path: 'Teachers',
      name: 'Teachers',
      component: () => import('@/views/php/SysMs/Teachers/PageRecord.vue'),
    },
    {
      path: 'TeacherLessonRt',
      name: 'TeacherLessonRt',
      component: () => import('@/views/php/SysMs/TeacherLessonRt/PageRecord.vue'),
    },
    {
      path: 'TeacherLessonReviewRt',
      name: 'TeacherLessonReviewRt',
      component: () => import('@/views/php/SysMs/TeacherLessonReviewRt/PageRecord.vue'),
    },

  ]
};

export default SysMs;
