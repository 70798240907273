
// 第二層 ROUTER 
// 系統管理
let ExamMs = {
  path: '/ExamMs',
  redirect: '/ExamMs/ExamEdit',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'ExamEdit',
      name: 'ExamEdit',
      component: () => import('@/views/php/ExamMs/ExamEdit/PageRecord.vue'),
    },
    {
      path: 'ExamPersonalDir',
      name: 'ExamPersonalDir',
      component: () => import('@/views/php/ExamMs/ExamPersonalDir/PageRecord.vue'),
    },
    { // 個人題目設定命題
      path: 'ExamPersonalSetting',
      name: 'ExamPersonalSetting',
      component: () => import('@/views/php/ExamMs/ExamPersonalSetting/PageRecord.vue'),
    },
    {
      path: 'ExamPaper',
      name: 'ExamPaper',
      component: () => import('@/views/php/ExamMs/ExamPaper/PageRecord.vue'),
    },
    {
      path: 'ExamPaperCombine',
      name: 'ExamPaperCombine',
      component: () => import('@/views/php/ExamMs/ExamPaperCombine/PageRecord.vue'),
    },
    {
      path: 'ExamPaperCombineComplete',
      name: 'ExamPaperCombineComplete',
      component: () => import('@/views/php/ExamMs/ExamPaperCombineComplete/PageRecord.vue'),
    },
    {
      path: 'ExamPaperReview',
      name: 'ExamPaperReview',
      component: () => import('@/views/php/ExamMs/ExamPaperReview/PageRecord.vue'),
    },
    {
      path: 'ExamPaperComplete',
      name: 'ExamPaperComplete',
      component: () => import('@/views/php/ExamMs/ExamPaperComplete/PageRecord.vue'),
    },
    {
      // 手動選題
      path: 'ExamTopicManualSelect',
      name: 'ExamTopicManualSelect',
      component: () => import('@/views/php/ExamMs/ExamTopicManualSelect/PageRecord.vue'),
    },
    {
      // 新增分類
      path: 'ExamPaperCreateCategory',
      name: 'ExamPaperCreateCategory',
      component: () => import('@/views/php/ExamMs/ExamPaperCreateCategory/PageRecord.vue'),
    },
    {
      // 新增分類
      path: 'ShareExam',
      name: 'ShareExam',
      component: () => import('@/views/php/ExamMs/ShareExam/PageRecord.vue'),
    },
    {
      // 新增分類
      path: 'EditExam',
      name: 'EditExam',
      component: () => import('@/views/php/ExamMs/EditExam/PageRecord.vue'),
    },
    {
      // 考卷設定
      path: 'ExamPaperSetting',
      name: 'ExamPaperSetting',
      component: () => import('@/views/php/ExamMs/ExamPaperSetting/PageRecord.vue'),
    },
    {
      // 下載考卷
      path: 'ExamPersonalVideo',
      name: 'ExamPersonalVideo',
      component: () => import('@/views/php/ExamMs/ExamPersonalVideo/PageRecord.vue'),
    },
    {
      // 影音資源管理
      path: 'PersonalEditVideoArea',
      name: 'PersonalEditVideoArea',
      component: () => import('@/views/php/ExamMs/PersonalEditVideoArea/PageRecord.vue'),
    },
    {
      // 個人影音管理
      path: 'Video',
      name: 'Video',
      component: () => import('@/views/php/ExamMs/Video/PageRecord.vue'),
    },
    {
      // 試卷練習
      path: 'TaskPractise',
      name: 'TaskPractise',
      component: () => import('@/views/php/ExamMs/TaskPractise/PageRecord.vue'),
    },
    {
      // 練習手動選題
      path: 'PractiseExamTopicManualSelect',
      name: 'PractiseExamTopicManualSelect',
      component: () => import('@/views/php/ExamMs/PractiseExamTopicManualSelect/PageRecord.vue'),
    },
    {
      // 練習新增分類
      path: 'PractiseExamPaperCreateCategory',
      name: 'PractiseExamPaperCreateCategory',
      component: () => import('@/views/php/ExamMs/PractiseExamPaperCreateCategory/PageRecord.vue'),
    },
    {
      // 練習考卷設定
      path: 'PractiseExamPaperSetting',
      name: 'PractiseExamPaperSetting',
      component: () => import('@/views/php/ExamMs/PractiseExamPaperSetting/PageRecord.vue'),
    },
    {
      // 試卷預覽
      path: 'TaskPractisePreview',
      name: 'TaskPractisePreview',
      component: () => import('@/views/php/ExamMs/TaskPractisePreview/PageRecord.vue'),
    },
    {
      // 練習手動選題
      path: 'PractiseExamTopicManualSelectPreview',
      name: 'PractiseExamTopicManualSelectPreview',
      component: () => import('@/views/php/ExamMs/PractiseExamTopicManualSelectPreview/PageRecord.vue'),
    },
    {
      // 練習新增分類
      path: 'PractiseExamPaperCreateCategoryPreview',
      name: 'PractiseExamPaperCreateCategoryPreview',
      component: () => import('@/views/php/ExamMs/PractiseExamPaperCreateCategoryPreview/PageRecord.vue'),
    },
    {
      // 練習考卷設定
      path: 'PractiseExamPaperSettingPreview',
      name: 'PractiseExamPaperSettingPreview',
      component: () => import('@/views/php/ExamMs/PractiseExamPaperSettingPreview/PageRecord.vue'),
    },
    {
      // 歷屆試卷
      path: 'ShareExamPaper',
      name: 'ShareExamPaper',
      component: () => import('@/views/php/ExamMs/ShareExamPaper/PageRecord.vue'),
    },
    
 
  ]
};

export default ExamMs;
