
// 第二層 ROUTER 
// 後台框架 
// 系統管理
let AuthPage = {
  path: '/AuthPage',
  component: () => import('@/views/AuthLayout.vue') ,
  children: [
    {
      path: 'SignIn',
      name: 'SignIn',
      component: () => import('@/views/SSOSignIn.vue'),
    },
  ]
};

export default AuthPage;
  