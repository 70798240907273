<template>
  <main style="background-image: url(/AdminApi/storage/excel/class.jpg);background-repeat: no-repeat; background-size: cover;">
    <span class="mask bg-black opacity-4" />
    <div class="vh-100 d-flex justify-content-center align-items-center">
      <div
        class="d-flex justify-content-center align-items-center"
        style="z-index: 99;"
      >
        <svg class="gegga">
          <defs>
            <filter id="gegga">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="7"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 20 -10"
                result="inreGegga"
              />
              <feComposite
                in="SourceGraphic"
                in2="inreGegga"
                operator="atop"
              />
            </filter>
          </defs>
        </svg>
        <svg
          class="snurra"
          width="200"
          height="200"
          viewBox="0 0 200 200"
        >
          <defs>
            <linearGradient id="linjärGradient">
              <stop
                class="stopp1"
                offset="0"
              />
              <stop
                class="stopp2"
                offset="1"
              />
            </linearGradient>
            <linearGradient
              y2="160"
              x2="160"
              y1="40"
              x1="40"
              gradientUnits="userSpaceOnUse"
              id="gradient"
              xlink:href="#linjärGradient"
            />
          </defs>
          <path
            class="halvan"
            d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"
          />
          <circle
            class="strecken"
            cx="100"
            cy="100"
            r="64"
          />
        </svg>
        <svg
          class="skugga"
          width="200"
          height="200"
          viewBox="0 0 200 200"
        >
          <path
            class="halvan"
            d="m 164,100 c 0,-35.346224 -28.65378,-64 -64,-64 -35.346224,0 -64,28.653776 -64,64 0,35.34622 28.653776,64 64,64 35.34622,0 64,-26.21502 64,-64 0,-37.784981 -26.92058,-64 -64,-64 -37.079421,0 -65.267479,26.922736 -64,64 1.267479,37.07726 26.703171,65.05317 64,64 37.29683,-1.05317 64,-64 64,-64"
          />
          <circle
            class="strecken"
            cx="100"
            cy="100"
            r="64"
          />
        </svg>
      </div> 
      <h3
        class="ms-3 text-white"
        style="z-index: 99;"
      >
        Loading...
      </h3>
    </div>
  </main>
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { config, ssoConfig } from '@/include/config';
import {
  h,
  ref,
  toRefs,
  reactive,
  watch,
  inject,
  onMounted,
  onBeforeUpdate,
  onUpdated
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  oltConsole,
  oAA,
  oApiError,
  oAuth,
  oAdminFrame,
  oUserInfo,
  oUrl
} from '@/lib/oltModule/initAdminVue3';
import moment from 'moment';
//import Header from '@/components/Header.vue';
import { useI18n } from 'vue-i18n';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';

import { FontIcon, SvgIcon } from '@progress/kendo-vue-common';
import { globeIcon } from '@progress/kendo-svg-icons';
import { Popup } from '@progress/kendo-vue-popup';
import { ComboBox } from '@progress/kendo-vue-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { Button, ButtonGroup , DropDownButton } from '@progress/kendo-vue-buttons';
import { Tooltip } from '@progress/kendo-vue-tooltip';

import { Form } from '@progress/kendo-vue-form';
import FormSignIn from '@/components/OltForm/FormSignIn.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CryptoJS from 'crypto-js';



export default {
  name: 'App',
  components: {
    //Header
    //Popup , 
    //ComboBox,
    //KButton : Button,
    //KButtonGroup : ButtonGroup,
    //DropDownButton : DropDownButton , 
    //Tooltip : Tooltip,
    //FontIcon,
    //SvgIcon,
    // Loading,
    // 'k-form': Form,
    // 'formsignin': FormSignIn

  },
  setup() {
    /**
     *  swal start
     */
    const swal = inject('$swal');
    /**
     *  swal end
     */

    /**
     *  i18n Start
     */
    const { t, locale } = useI18n({
      useScope: 'global' // 必需設定 SCOPE 才能覆蓋設定
    });

    locale.value = 'zh-TW';
    /**
     *  i18n end
     */

    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const isLoading = ref(false);
    let routerPath = route.path;
    const routerArr = routerPath.split('/');
    const module = routerArr[1];
    const fun = routerArr[2];

    const isTaskComplete = ref(false);
    const isExamPaperTaskComplete = ref(false);
    const isReviewExamPaperTaskComplete = ref(false);

    onMounted(async () => {
      //console.log('SingnIn onMounted');
      //console.log('store : ', store);
      //let isLogin = oAuth.isLogin(store);
      //console.log('isLogin : ', isLogin);
      //let getToken = oAuth.getToken(store);
      //console.log('getToken : ', getToken);

      // logout();
       if(router && router.currentRoute && router.currentRoute.value && router.currentRoute.value.query && router.currentRoute.value.query.v){
         // console.log("router => ", router);
         let str = decryptByAES(router.currentRoute.value.query.v);
         // console.log("str => ", str);

         if(str && str != ''){
           let user = JSON.parse(str);

           // console.log('user => ', user);
           if(user && user.admin_users_sso_verify){
            
             if(user.admin_users_sso_verify.admin_users){
               oUserInfo.setUserInfo(store, user.admin_users_sso_verify.admin_users);
             }
            
             isLoading.value = true;
             getUserRoles(user.admin_users_sso_verify.admin_users.uid,user.admin_users_sso_verify);
           }
         }
       }else{
         router.push({ path: '/AuthPage/SignIn' });
       }

      //console.log('store2 : ', store);
      //isLogin = oAuth.isLogin(store);
      //console.log('isLogin2 : ', isLogin);
      //getToken = oAuth.getToken(store);
      //console.log('getToken2 : ', getToken);
    });
    // Reload MDInput End

    function getUserRoles(uid,obj){
      const apiParam = {
        restApiDriver: config.restApiDriver ,// token key from veex store 
        // auth
        token: oAuth.getToken(store),// token key from veex store 
        // Debug Mode 
        apiGetPageDebug:false , // API getPageRecord 除錯模式
        apiGetRtAllRecordDebug:false, // rtAllRecord Debug 
        apiGetRtPageRecordDebug:false , // API getRt 除錯模式        
        // API Filter 
        apiName:'sso_user_roles_custom',
        customFilter:false, 
        pageSearch:{
          uid: uid,
        } , // json format 
        pageGroup:'' , // string 
        pageGroupBy:'' , // string 
        pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt 
        rtSet:{
        },
        queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
        module:module , // module
        fun:fun , // fun
        act:'view' , // act
      };

      oAA.queueGetAllVue3(apiParam).then(
        async res => 
        {
          if(res && res.pageRecord && res.pageRecord.length > 0){
            obj.role = res.pageRecord;
            oAuth.pushUserLoginToSSO(router, store, obj);
            getUserRole(obj.admin_users.uid);
          }else{
            isLoading.value = false;
            swal.fire({
              icon:'warning',
              html:'您的帳號無權限使用雲端題庫命題系統<br>請洽系統管理員',
              buttonsStyling: true,
              confirmButtonClass: 'md-button md-success',
              confirmButtonText:t('messages.confirm_button'),
              backdrop: false,
            }).then( result => {
              if (result.value) {
                let goToUrl = `${ssoConfig.protocol}${ssoConfig.domain}${ssoConfig.path}${ssoConfig.logout}`;

                window.location.href = goToUrl;
              }
            });
          }
        }
      )
        .catch((err)=> {
          console.log(err);
          console.log(err.response);
          oApiError.chkError(err,swal,t);  
        });
    }

    onUpdated(async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals 
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    }) ;

    const schema = yup.object({
      account: yup
        .string()
        .min(2, t('messages.min', { length: 2 }))
        .max(32, t('messages.max', { length: 32 }))
        .required(t('messages.required')),
      password: yup
        .string()
        .min(2, t('messages.min', { length: 2 }))
        .max(32, t('messages.max', { length: 32 }))
        .required(t('messages.required'))
    });

    function onSignin(form) {
      console.log('onSignin');
      console.log('form : ', form);
    }

    const { handleSubmit, resetForm } = useForm({
      validationSchema: schema
    });

    const {
      value: account,
      errorMessage: accountError,
      meta: accountMeta,
      resetField: accountResetField
    } = useField('account');
    const {
      value: password,
      errorMessage: passwordError,
      meta: passwordMeta,
      resetField: passwordResetField
    } = useField('password');

    // 這個目前沒有在用了 使用 KNEDO 的FORM 的COMPONENT 去做 EMIT 和 PROPS 
    const onSubmit = handleSubmit((values) => {
      // pretty print the values object
      //console.log('onSubmit : ', values);
      postOneRecord(values);
    });

    function postOneRecord(form) {
      //console.log('postOneRecord : ', form);
      isLoading.value = true;
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: '', // token key from veex store
        // Debug Mode
        apiPostDebug: false, // API 新增 除錯模式
        apiName: 'admin_users_login',
        form: form
      };

      //console.log(param);
      let resObj = oAA.postRecordVue3(apiParam);

      resObj
        .then((res) => {
          //console.log('res : ',res);
          isLoading.value = false;
          swal
            .fire({
              icon: 'success',
              html: t('messages.login_success', { ex: '' }),
              buttonsStyling: true,
              confirmButtonClass: 'md-button md-success',
              confirmButtonText: t('messages.confirm_button')
            })
            .then((result) => {
              //console.log('result : ', result);
              if (result.value) {
                //console.log('result value : ', result.value);
                let user = Object.values(res)[0];

                // console.log('user : ', user);
                
                //resetForm();
                //getOneRecord(editPkyId.value);
                //emit('editCallBack');
                // eslint-disable-next-line no-undef
                //oAdminFrame.hideModal('edit');
                oUserInfo.setUserInfo(store, user.admin_users);

                //console.log('store : ', store);
                oAuth.pushUserLogin(router, store, user);
                //console.log('store1 : ', store);
                //let isLogin = oAuth.isLogin(store);
                //console.log('isLogin : ', isLogin);
                //let getToken = oAuth.getToken(store);
                //console.log('getToken : ', getToken);
              }
            });
        })
        .catch((err) => {
          isLoading.value = false;
          oApiError.chkError(err, swal);

          //console.log('err.response : ', err.response);
          //console.log('err.response.status : ', err.response.status);
          //console.log('err.response.data.error : ', err.response.data.error);
          if(err.response.status == '422'){
            swal.fire({
              icon: 'error',
              html: t('messages.login_check_fail', { ex: '' }),
              buttonsStyling: true,
              confirmButtonClass: 'md-button md-success',
              confirmButtonText: t('messages.confirm_button')
            });
          }
        });
    }


    function postOneRecordToSSO(form) {
      //console.log('postOneRecord : ', form);
      isLoading.value = true;
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: '', // token key from veex store
        // Debug Mode
        apiPostDebug: false, // API 新增 除錯模式
        apiName: 'admin_users_sso_login',
        form: form
      };

      //console.log(param);
      let resObj = oAA.postRecordVue3(apiParam);

      resObj
        .then((res) => {
          isLoading.value = false;
          //console.log('res : ',res);
          swal
            .fire({
              icon: 'success',
              html: t('messages.login_success', { ex: '' }),
              buttonsStyling: true,
              confirmButtonClass: 'md-button md-success',
              confirmButtonText: t('messages.confirm_button')
            })
            .then((result) => {
              //console.log('result : ', result);
              if (result.value) {
                //console.log('result value : ', result.value);
                let user = Object.values(res)[0];

                //console.log('user : ', user);
                
                //resetForm();
                //getOneRecord(editPkyId.value);
                //emit('editCallBack');
                // eslint-disable-next-line no-undef
                //oAdminFrame.hideModal('edit');
                oUserInfo.setUserInfo(store, user.admin_users);

                //console.log('store : ', store);
                oAuth.pushUserLoginToSSO(router, store, user);
                //console.log('store1 : ', store);
                //let isLogin = oAuth.isLogin(store);
                //console.log('isLogin : ', isLogin);
                //let getToken = oAuth.getToken(store);
                //console.log('getToken : ', getToken);
              }
            });
        })
        .catch((err) => {
          isLoading.value = false;
          oApiError.chkError(err, swal);

          //console.log('err.response : ', err.response);
          //console.log('err.response.status : ', err.response.status);
          //console.log('err.response.data.error : ', err.response.data.error);
          if(err.response.status == '422'){
            swal.fire({
              icon: 'error',
              html: t('messages.login_check_fail', { ex: '' }),
              buttonsStyling: true,
              confirmButtonClass: 'md-button md-success',
              confirmButtonText: t('messages.confirm_button')
            });
          }
        });
    }

    function logout(){
      oAuth.logoutEx(router,store);
      oUserInfo.cleanAdminPermissions(store);
    }


    function goToSubmit (form) {
      //alert(JSON.stringify(from, null, 2));
      if(form.login_type.value == 'sso'){
        //console.log('form informatin : ', form.login_type.value);
        postOneRecordToSSO(form);
      }
      else{
        postOneRecord(form);
      }

    }

    const signinForm = ref(null) ; 

    function decryptByAES(text) {
      let key = CryptoJS.enc.Utf8.parse(ssoConfig.aesKey);
      let iv = CryptoJS.enc.Utf8.parse(ssoConfig.aesIv);
      let str = decodeURIComponent(text);
      // console.log("str => ", str);

      if(str && str != ''){
        let decrypt = CryptoJS.AES.decrypt(str, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });

        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
      }
      return '';
      
    }

    function getUserRole(uid){
      const apiParam = {
        restApiDriver: config.restApiDriver ,// token key from veex store 
        // auth
        token: oAuth.getToken(store),// token key from veex store 
        // Debug Mode 
        apiGetPageDebug:false , // API getPageRecord 除錯模式
        apiGetRtAllRecordDebug:false, // rtAllRecord Debug 
        apiGetRtPageRecordDebug:false , // API getRt 除錯模式        
        // API Filter 
        apiName:'sso_user_groups_custom',
        customFilter:false, 
        pageSearch:{
          uid: uid,
        } , // json format 
        pageGroup:'' , // string 
        pageGroupBy:'' , // string 
        pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt 
        rtSet:{
        },
        queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
        module:module , // module
        fun:fun , // fun
        act:'view' , // act
      };

      oAA.queueGetAllVue3(apiParam).then(
        async res => 
        {
          console.log('res:',res);
          if(res && res.pageRecord && res.pageRecord.sso_users && res.pageRecord.sso_users.length > 0 && res.pageRecord.task_sso_buffer && res.pageRecord.task_sso_buffer.length > 0){
            // 派發教科書版本設定
            await editionPolicyTask(res.pageRecord);
            // // 派發命題任務
            await examPaperTask(res.pageRecord);
            // // 派發審題任務
            await reviewExamPaperTask(res.pageRecord);
            // // 派發二審圖任務
            // await proofreadingSecondTask(res.pageRecord);
            // // 派發教育局審圖任務
            // await proofreadingEduTask(res.pageRecord);
          }else{
            isLoading.value = false;
            router.push({path:'/AdministrativeMs/Dashboard'});
          }
        }
      )
        .catch((err)=> {
          console.log(err);
          console.log(err.response);
          oApiError.chkError(err,swal,t);  
        });
    }

    async function editionPolicyTask(record){
      if(record && record.sso_users && record.sso_users.length > 0 && record.task_sso_buffer && record.task_sso_buffer.length > 0){
        for(let j = 0; j < record.task_sso_buffer.length; j++){
          for(let i = 0; i < record.sso_users.length; i++){
            if(record.task_sso_buffer[j].tlid == 18 && record.task_sso_buffer[j].susgid == record.sso_users[i].susgid){
              await assignEditionPolicyTask({
                tsbid: record.task_sso_buffer[j].tsbid,
                uid: record.sso_users[i].uid,
                ayid: record.task_sso_buffer[j].ayid,
                policy_id: record.sso_users[i].essrid,
                tlid: record.task_sso_buffer[j].tlid,
                code: record.sso_users[i].school_code,
                name: record.sso_users[i].school_name,
                description: record.sso_users[i].school_description,
              });
            }
          }
        }
      }
      isTaskComplete.value = true;
      checkTaskAssignComplete(); 
    }
    
    async function assignEditionPolicyTask(form){
      // console.log('assignEditionPolicyTask : ', form);
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: oAuth.getToken(store), // token key from veex store
        // Debug Mode
        apiPostDebug: false, // API 新增 除錯模式
        apiName: 'drawing_policy_task_assign',
        form: form
      };
      //console.log(param);
      let resObj = oAA.postRecordVue3(apiParam);

      resObj.then((res) => {
        // console.log(res);
      })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          // oApiError.chkError(err, swal);
        });
    }

    async function examPaperTask(record){
      if(record && record.sso_users && record.sso_users.length > 0 && record.task_sso_buffer && record.task_sso_buffer.length > 0){
        for(let j = 0; j < record.task_sso_buffer.length; j++){
          for(let i = 0; i < record.sso_users.length; i++){
            if((record.task_sso_buffer[j].tlid == 20 || record.task_sso_buffer[j].tlid == 25) && record.task_sso_buffer[j].account && record.task_sso_buffer[j].account == record.sso_users[i].username){
              await assignExamPaperTask({
                tsbid: record.task_sso_buffer[j].tsbid,
                uid: record.sso_users[i].uid,
                ayid: record.task_sso_buffer[j].ayid,
                essrid: record.task_sso_buffer[j].group_id,
                policy_id: record.task_sso_buffer[j].policy_id,
                group_id: record.task_sso_buffer[j].group_id,
                tlid: record.task_sso_buffer[j].tlid,
                name: record.task_sso_buffer[j].task_name,
                description: record.task_sso_buffer[j].task_description,
              });
              break;
            }
          }
        }
      }
      isExamPaperTaskComplete.value = true;
      checkTaskAssignComplete(); 
    }

    async function assignExamPaperTask(form){
      console.log('assignExamPaperTask : ', form);
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: oAuth.getToken(store), // token key from veex store
        // Debug Mode
        apiPostDebug: false, // API 新增 除錯模式
        apiName: 'drawing_task_assign',
        form: form
      };
      //console.log(param);
      let resObj = oAA.postRecordVue3(apiParam);

      resObj.then((res) => {
        console.log(res);
      })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        // oApiError.chkError(err, swal);
        });
    }
    
    async function reviewExamPaperTask(record){
      if(record && record.sso_users && record.sso_users.length > 0 && record.task_sso_buffer && record.task_sso_buffer.length > 0){
        for(let j = 0; j < record.task_sso_buffer.length; j++){
          for(let i = 0; i < record.sso_users.length; i++){
            if((record.task_sso_buffer[j].tlid == 21 || record.task_sso_buffer[j].tlid == 26) && record.task_sso_buffer[j].account && record.task_sso_buffer[j].account == record.sso_users[i].username){
              await assignReviewExamPaperTask({
                tsbid: record.task_sso_buffer[j].tsbid,
                uid: record.sso_users[i].uid,
                ayid: record.task_sso_buffer[j].ayid,
                essrid: record.task_sso_buffer[j].group_id,
                policy_id: record.task_sso_buffer[j].policy_id,
                group_id: record.task_sso_buffer[j].group_id,
                tlid: record.task_sso_buffer[j].tlid,
                name: record.task_sso_buffer[j].task_name,
                description: record.task_sso_buffer[j].task_description,
              });
              break;
            }
          }
        }
      }
      isReviewExamPaperTaskComplete.value = true;
      checkTaskAssignComplete(); 
    }

    async function assignReviewExamPaperTask(form){
      console.log('assignRevierExamPaperTask : ', form);
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: oAuth.getToken(store), // token key from veex store
        // Debug Mode
        apiPostDebug: false, // API 新增 除錯模式
        apiName: 'review_task_assign',
        form: form
      };
      //console.log(param);
      let resObj = oAA.postRecordVue3(apiParam);

      resObj.then((res) => {
        console.log(res);
      })
        .catch((err) => {
          console.log(err);
          // console.log(err.response.data);
        // oApiError.chkError(err, swal);
        });
    }

    function checkTaskAssignComplete(){
      if(isTaskComplete.value === true && isExamPaperTaskComplete.value === true && isReviewExamPaperTaskComplete.value === true){
        // console.log('Complete');
        isLoading.value = false;
        router.push({path:'/AdministrativeMs/Dashboard'});
      }
    }


    return {
      // i18n
      t,
      locale,

      hiddenHeader: false,
      // api setting
      setDebugMode: false,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo: { coin: 0, point: 0 },
      token: null,
      account,
      accountError,
      accountMeta,
      password,
      passwordError,
      passwordMeta,
      signinForm,
      // function
      onSignin,
      goToSubmit,
      logout,
      isLoading,
      fullPage: true,     
      decryptByAES,

    };
  },
  created() {},
  mounted() {
    oAdminFrame.initMD();
  },
  methods: {}
};
</script>
