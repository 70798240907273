// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { ssoConfig } from '@/include/config';
// init oltConsole
export class auth{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('Init auth!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this
  chkSignup(router,store,swal,t){
    //console.log('store : ');
    //console.log(store.getters);
    //console.log(store.state);
    //console.log('sessionStorage : ');
    //console.log(JSON.parse(sessionStorage.getItem('user')));
    //console.log('i18n : ');
    //console.log(t);
    if(!store.getters.isSignup){
      swal.fire({
        icon:'warning',
        html:t('messages.chk_signup' , {ex:''}),
        confirmButtonText:t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 2000
      });
      router.push('Signup'); 
    }
  }
  
  // eslint-disable-next-line class-methods-use-this
  alreadySignup(router,store,swal,t){
    //console.log('store : ');
    //console.log(store.getters);
    //console.log(store.state);
    //console.log('sessionStorage : ');
    //console.log(JSON.parse(sessionStorage.getItem('user')));
    //console.log('i18n : ');
    //console.log(t);
    if(store.getters.isSignup){
      swal.fire({
        icon:'warning',
        html:t('messages.already_signup' , {ex:''}),
        confirmButtonText:t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 2000
      });
      router.push('PrivacyPolicy'); 
    }
  }

  // eslint-disable-next-line class-methods-use-this
  alreadyLogin(router,store,swal,t){
    //console.log('store : ');
    //console.log(store.getters);
    //console.log(store.state);
    //console.log('sessionStorage : ');
    //console.log(JSON.parse(sessionStorage.getItem('user')));
    //console.log('i18n : ');
    //console.log(t);
    if(store.getters.isLogin){
      swal.fire({
        icon:'warning',
        html:t('messages.already_login' , {ex:''}),
        confirmButtonText:t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 2000
      });
      router.go(-1) ; 
    }
  }

  // eslint-disable-next-line class-methods-use-this
  chkLogin(router,store,swal,t){
    //console.log('router : ');
    //console.log(router);
    //console.log('store : ');
    //console.log(store.getters);
    //console.log(store.state);
    //console.log('sessionStorage : ');
    //console.log(JSON.parse(sessionStorage.getItem('user')));
    //console.log('i18n : ');
    //console.log(t);
    if(!store.getters.isLogin){
      swal.fire({
        icon:'error',
        html:t('messages.chk_login' , {ex:'才可以使用本功能'}),
        confirmButtonText:t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 3000
      });
      router.push('/LoginSelect');
    }
    else{
      // 認證成功 回傳 Token KEY 

      console.log('token : ' , store.state.auth);
      return store.state.auth.user.tokens.token ; 
    }
  }

  // 判斷狀態是否為登入 
  // eslint-disable-next-line class-methods-use-this
  isLogin(store){
    //console.log('store : ', store);
    if(store.getters.isLogin){
      return true ; 
    }
    return false ; 

  }

  // 將 登入資訊 記到 Store 中
  // eslint-disable-next-line class-methods-use-this
  pushUserLogin(router,store,user){
    //console.log('pushUserLogin start');
    store.commit('cleanAdminPermissions');
    store.commit('loginSuccess',user);
    // 20230326 FIEND Modify  Start 
    //登入後會回到上次的頁面
    if(store.getters.isLocationUrl){
      //console.log('isLocationUrl...');
      //router.currentRoute.value.matched[0].path
      //let locationUrl = store.state.url.locationUrl.routerPath.matched[0].path + y ;
      let locationUrl = store.state.url.locationUrl ;

      //console.log('routePath' , store.state.url.locationUrl.routerValue);
      //console.log('locationUrl : ' , locationUrl);

      //store.dispatch('cleanLocationUrl'); // 清空 locationUrl 
      if(locationUrl){
        router.push({path:'/Home'});
        // router.push(locationUrl);
      }
      //router.push({ path:'Home' , query:{page:1,blid:1}} );
    }
    else{
      //console.log('else...');
      router.push({path:'/Home'});
    }
    //console.log('location url : ' , store.state.url);
    //router.push('/Home');
    
  }

  // 將 登入資訊 記到 Store 中
  // eslint-disable-next-line class-methods-use-this
  pushUserLoginToSSO(router,store,user){

    //console.log(' pushUserLoginToSSo not commit : ' , store);
    store.commit('cleanAdminPermissions');

    store.commit('loginSuccess',user);

    //console.log(' pushUserLoginToSSo commit : ' , store);
    // if(store.getters.isLocationUrl){
    //   let locationUrl = store.state.url.locationUrl ;

    //   if(locationUrl){
    //     //router.push(locationUrl);
    //     router.push({path:'/AdministrativeMs/Dashboard'});
    //   }
    // }
    // else{
    //   router.push({path:'/AdministrativeMs/Dashboard'});
    // }
  }

  // eslint-disable-next-line class-methods-use-this
  pushFirstLoginToSSO(router,store,user){

    store.commit('cleanAdminPermissions');
    store.commit('loginSuccess',user);
    //console.log('pushFirstLoginToSSO store 2 : ', store.state.auth.user) ; 
    //console.log('pushFirstLoginToSSO store 2 is login : ', store.getters.isLogin) ; 
    //store.commit('loginSuccess',user);
    router.push({path:'/AdministrativeMs/Dashboard'});
  }



  
  // eslint-disable-next-line class-methods-use-this
  logout(router,store,swal,t){
    //console.log('store : ');
    //console.log(store.getters.isLogin);
    //console.log(store.state.auth);
    
    //store.commit('locationUrl',router.currentRoute.value);
    //console.log('logout store : ',store);
    //console.log('logout router : ', router.currentRoute.value);
    store.dispatch('cleanLocationUrl');

    let locationUrl = {};

    // 將來源路徑物件儲存在 STORE 和 SESSION STORATE 
    locationUrl.path = router.currentRoute.value.path ; 
    locationUrl.query = router.currentRoute.value.query;
    
    store.commit('locationUrl',locationUrl);


    swal.fire({
      icon:'warning',
      text:t('messages.logout_confirm' , {ex:''}),
      confirmButtonText:t('messages.confirm_button'),
      cancelButtonText:t('messages.cancel_button'),
      showConfirmButton: true,
      showCancelButton: true,
    }).then( result => {
      if (result.isConfirmed) {
        store.commit('logout');
        // router.push('/AuthPage/SignIn');
        let goToUrl = `${ssoConfig.protocol}${ssoConfig.domain}${ssoConfig.path}${ssoConfig.logout}`;
        window.location.href = goToUrl;
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  logoutEx(router,store){
    store.commit('logout');
    router.push('/AuthPage/SignIn');
  }

  // eslint-disable-next-line class-methods-use-this
  isGuestLogin(store){
    if(store.getters.isGuest){
      return true ; 
    }
    return false ;
  }

  // eslint-disable-next-line class-methods-use-this
  setGuest(store,Obj){
    store.dispatch('setGuest',Obj);
  }

  // eslint-disable-next-line class-methods-use-this
  cleanGuest(store){
    store.dispatch('cleanGuest');
  }

  // eslint-disable-next-line class-methods-use-this
  getLoginType(store){
    if(store.getters.isLogin && store.state.auth != null && store.state.auth.user != null && store.state.auth.user.admin_users != null){
      return store.state.auth.user.login_type ; 
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getUUId(store){
    let uuid = '';

    if(!store.getters.isUUId){
      uuid = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      store.dispatch('setUUId',uuid);
    }else{
      uuid = store.state.guestAuth.uuid;
    }
    return uuid; 
  }

  // eslint-disable-next-line class-methods-use-this
  cleanUUId(store){
    store.dispatch('cleanUUId');
  }

  // eslint-disable-next-line class-methods-use-this
  getToken(store){
    if(store.getters.isLogin){
      return store.state.auth.user.tokens.token ; 
    }else if(store.getters.isGuest){
      return store.state.guestAuth.guest.tokens.token ;
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  getUser(store){
    if(store.getters.isLogin && store.state.auth != null && store.state.auth.user != null && store.state.auth.user.admin_users != null){
      return store.state.auth.user.admin_users ; 
    }else if(store.getters.isGuest){
      return store.state.guestAuth.guest;
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getUid(store){
    if(store.getters.isLogin && store.state.auth != null && store.state.auth.user != null && store.state.auth.user.admin_users != null){
      //return store.state.auth.user.admin_users ; 
      return store.state.auth.user.admin_users.uid;
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  getUserProfile(store){
    if(store.getters.isLogin && store.state.auth != null && store.state.auth.user != null && store.state.auth.user.admin_users != null){
      return store.state.auth.user ; 
    }
    return null;
  }



}