// 第二層 ROUTER
// 系統管理
let MemMs = {
  path: '/MemMs',
  redirect: '/MemMs/Users',
  component: () => import('@/views/OltFrame.vue'),
  children: [
    {
      path: 'Users',
      name: 'Users',
      component: () => import('@/views/php/MemMs/Users/PageRecord.vue'),
    },
    {
      path: 'UserRelationRt',
      name: 'UserRelationRt',
      component: () => import('@/views/php/MemMs/UserRelationRt/PageRecord.vue'),
    },
    {
      path: 'UserShareRt',
      name: 'UserShareRt',
      component: () => import('@/views/php/MemMs/UserShareRt/PageRecord.vue'),
    },
    {
      path: 'UserLevel',
      name: 'UserLevel',
      component: () => import('@/views/php/MemMs/UserLevel/PageRecord.vue'),
    },
    {
      path: 'PointSetting',
      name: 'PointSetting',
      component: () => import('@/views/php/MemMs/PointSetting/PageRecord.vue'),
    },
    {
      path: 'QuestionPointEvent',
      name: 'QuestionPointEvent',
      component: () => import('@/views/php/MemMs/QuestionPointEvent/PageRecord.vue'),
    },
    {
      path: 'QuestionPointSetting',
      name: 'QuestionPointSetting',
      component: () => import('@/views/php/MemMs/QuestionPointSetting/PageRecord.vue'),
    },
    {
      path: 'RegisterMs',
      name: 'RegisterMs',
      component: () => import('@/views/php/MemMs/RegisterMs/PageRecord.vue'),
    },
    {
      path: 'PointEvent',
      name: 'PointEvent',
      component: () => import('@/views/php/MemMs/PointEvent/PageRecord.vue'),
    },
    {
      path: 'PointType',
      name: 'PointType',
      component: () => import('@/views/php/MemMs/PointType/PageRecord.vue'),
    },
  ]
};

export default MemMs;
