/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { config } from '@/include/config';
// init oltConsole
export class oltUrl{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('oltUrl!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  // 記錄 之後 想要回來的 地方 ， 例如 登入後回到強制登入頁 
  // commit locaitonUrl
  locationUrl(store,urlObj){
    //console.log('oUrl locationUrl test :  ');
    //store.dispatch('locationUrl',urlObj);
    store.commit('locationUrl',urlObj);
    //console.log(store.state);
  }

  cleanLocationUrl(store){
    store.dispatch('cleanLocationUrl');
    //console.log(store.state);
  }

  getPathQuery(router,store){

    let pathQuery = {} ; 
    //console.log('path getter Query : ',store.getters.isPathQuery);
    //console.log('path state Query : ',store.state.url.pathQuery);

    if(config.pathQueryMode == 'router'){
      if(store.getters.isPathQuery){
        pathQuery.path = store.state.url.pathQuery.path ; 
        pathQuery.query = store.state.url.pathQuery.query ; 
      }
    }
    else{
      pathQuery.path = router.currentRoute.value.path ; 
      pathQuery.query = router.currentRoute.value.query;
    }
    return pathQuery ; 

  }

  
  routerPushToUrl(router,store,goToUrl){

    // 將物件寫進 記憶體物件容器 store 
    //router.currentRoute.value.matched[0].path
    //this.store.dispatch(store);
    // 將現行 的 router 資訊寫在容器裡
    store.dispatch('cleanLocationUrl');

    //goToUrl.routerValue = router.currentRoute.value ; 
    //this.locationUrl(store,goToUrl);

    //console.log('routerUrl : ' , router.currentRoute.value.path);
    //console.log('routerUrl : ' , router.currentRoute.value.query);

    let locationUrl = {};

    // 將來源路徑物件儲存在 STORE 和 SESSION STORATE 
    locationUrl.path = router.currentRoute.value.path ; 
    locationUrl.query = router.currentRoute.value.query;
    
    store.commit('locationUrl',locationUrl);
    //console.log('routerPushToUrl : ' , goToUrl);


    //console.log( 'locationUrl' , goToUrl);
    // 物件容器內容
    //console.log( 'store getters : ' , store.getters);
    //console.log( 'store url locationUrl : ' , store.state.url.locationUrl);
    //router.push( { path: page , query :{test1:1,test2:2} } );
    //router.push( { path: page , query : qobj } );
    // 一侓不帶 GET , 所有參數傳輸全部用 ROUTER 的 QUERY 帶
    //router.push( { path: goToUrl.path } );
    //console.log('config : ' , config);
    if(config.pathQueryMode == 'router'){
      // 啟用 ROUTER 模式將QUERY寫入 容器
      store.commit('setupPathQuery',goToUrl);
      router.push( { path: goToUrl.path } );
    }
    else{
      router.push( goToUrl );
    }

  }

  getStoreLocationUrl(store){
    //store.getters.isLocationUrl
    if(store.getters.isLocationUrl){
      //console.log( 'store url locationUrl : ' , store.state.url.locationUrl);
      return store.state.url.locationUrl ; 
    }
    return '';
  }


  // 回傳 前幾次瀏覽記錄的 路徑 , -1 ~ -n 往上 N層 
  getClickHistory(store,key){

  }

  // 查詢位置在 瀏覽記錄容器陣列的那一個索引上
  // 回傳 索引位置
  indexOfClickHistory(store,url){
  }

  // 寫入到 瀏覽記錄容器陣列尾端
  pushClickHistory(store,url){
  }

  // 移除 瀏覽記錄容器陣列的最後一筆
  popClickHistory(store,url){
  }

  // 寫入到 瀏覽記錄容器陣列前端
  unshiftClickHistory(store,url){
  }

  // 移除瀏覽記錄容器陣列的第一筆  
  shiftClickHistory(store,url){
  }

  // 移除瀏覽記錄容器陣列 指定的索引位置及數量  
  // 容器，索引位置，數量
  spliceClickHistory(store,key,num){ 
  }


}