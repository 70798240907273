/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  openid: oltEncrypt.storeDecodeObject(sessionStorage.getItem('openid')),
  ltid: oltEncrypt.storeDecodeText(sessionStorage.getItem('ltid'))
};
/*
  mutations 必須是同步執行，而 actions 則可以異步執行。
  */
export const actions = {
  setOpenIdFacebook({ commit }) {
    commit('setOpenIdFacebook');
  },
  setOpenIdGoogle({ commit }) {
    commit('setOpenIdGoogle');
  },
  setOpenIdApple({ commit }) {
    commit('setOpenIdApple');
  },
  setOpenIdSSO({ commit }) {
    commit('setOpenIdSSO');
  }
};

/*
  Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。
  
  mutation必定是同步函數，沒有例外
  mutation是vuex中唯一可以改動state的方法
  也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程
  
  發出一個action(dispatch an action)
  接收到action後，執行對應的mutation (commit a mutation)
  透過mutation更改state
  */
export const mutations = {
  setOpenIdFacebook(state) {
    let json = sessionStorage.getItem('fb_login');

    let openid = oltEncrypt.storeDecodeObject(json);

    //console.log('json :' , json);
    //console.log('decode :' , openid);
    sessionStorage.setItem('openid', json);
    sessionStorage.setItem('ltid', oltEncrypt.storeEncodeText('facebook'));
    state.openid = openid;
    state.ltid = 'facebook';
  },
  setOpenIdGoogle(state) {
    let json = sessionStorage.getItem('google_login');

    let openid = oltEncrypt.storeDecodeObject(json);

    sessionStorage.setItem('openid', json);
    sessionStorage.setItem('ltid', oltEncrypt.storeEncodeText('google'));
    state.openid = openid;
    state.ltid = 'google';
  },
  setOpenIdApple(state) {
    let json = sessionStorage.getItem('apple_login');

    let openid = oltEncrypt.storeDecodeObject(json);

    sessionStorage.setItem('openid', json);
    sessionStorage.setItem('ltid', oltEncrypt.storeEncodeText('appleid'));
    state.openid = openid;
    state.ltid = 'appleid';
  },
  setOpenIdSSO(state) {
    let json = sessionStorage.getItem('sso_login');

    let openid = oltEncrypt.storeDecodeObject(json);

    sessionStorage.setItem('openid', json);
    sessionStorage.setItem('ltid', oltEncrypt.storeEncodeText('sso'));
    state.openid = openid;
    state.ltid = 'sso';
  }
};

export const getters = {
  isOpenid(state) {
    if (state.openid) {
      return true;
    }
    return false;
  },
  isLtid(state) {
    if (state.ltid) {
      return true;
    }
    return false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
