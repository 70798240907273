// 第二層 ROUTER 
// 系統管理
let AdminUserSelfMs = {
  path: '/AdminUserSelfMs',
  redirect: '/AdminUserSelfMs/ProfileOverview',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      // 個人資訊
      path: 'ProfileOverview',
      name: 'ProfileOverview',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileOverview/PageRecord.vue'),
    },
    {
      // 個人設定
      path: 'ProfileSettings',
      name: 'ProfileSettings',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileSettings/PageRecord.vue'),
    },
    {
      // 訊息通知
      path: 'ProfileMessages',
      name: 'ProfileMessages',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileMessages/PageRecord.vue'),
    },
    {
      // 訊息通知
      path: 'ProfileMoreInformation',
      name: 'ProfileMoreInformation',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileMoreInformation/PageRecord.vue'),
    },
    {
      // 訊息通知
      path: 'ProfileContent',
      name: 'ProfileContent',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileContent/PageRecord.vue'),
    },


  ]
};
    
export default AdminUserSelfMs;
    