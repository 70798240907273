/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
// init oltConsole
export class oltEduSysSchoolRt{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('oltEduSysSchoolRt!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  
  setEduSysSchoolRt(store,Obj){
    //console.log('oUrl locationUrl test :  ');
    store.dispatch('setSysEduSchoolRt',Obj);
    //console.log(store.state);
  }

  cleanEduSysSchoolRt(store){
    store.dispatch('cleanSysEduSchoolRt');
    //console.log(store.state);
  }

  getEduSysSchoolRt(store){
    if(store.getters.isSysEduSchoolRt){
      return store.state.eduSysSchoolRt.sysEduSchoolRt;
    }
    return false ; 
  }

}