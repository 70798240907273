// 第二層 ROUTER 
// 教育行政管理
let EducationAdministrativeMs = {
  path: '/EducationAdministrativeMs',
  redirect: '/EducationAdministrativeMs/SchoolMs',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      // 學校管理
      path: 'SchoolMs',
      name: 'SchoolMs',
      component: () => import('@/views/php/EducationAdministrativeMs/SchoolMs/PageRecord.vue'),
    },
    {
      // 教育局公告
      path: 'EducationBureauAnnouncement',
      name: 'EducationBureauAnnouncement',
      component: () => import('@/views/php/EducationAdministrativeMs/EducationBureauAnnouncement/PageRecord.vue'),
    },
    {
      // 聯合命題管理
      path: 'AllianceMs',
      name: 'AllianceMs',
      component: () => import('@/views/php/EducationAdministrativeMs/AllianceMs/PageRecord.vue'),
    },
    {
      // 各校出卷進度
      path: 'TaskExamPaperMs',
      name: 'TaskExamPaperMs',
      component: () => import('@/views/php/EducationAdministrativeMs/TaskExamPaperMs/PageRecord.vue'),
    },
  ]
};
    
export default EducationAdministrativeMs;
    