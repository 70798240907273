
// 第二層 ROUTER 
// 教務行政管理
let AdministrativeMs = {
  path: '/AdministrativeMs',
  redirect: '/AdministrativeMs/Dashboard',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'Dashboard',
      name: 'Dashboard',
      component: () => import('@/views/php/AdministrativeMs/Dashboard/PageRecord.vue'),
    },
    {
      path: 'SchoolAnnouncement',
      name: 'SchoolAnnouncement',
      component: () => import('@/views/php/AdministrativeMs/SchoolAnnouncement/PageRecord.vue'),
    },
    {
      path: 'SchoolAnnouncementPerson',
      name: 'SchoolAnnouncementPerson',
      component: () => import('@/views/php/AdministrativeMs/SchoolAnnouncementPerson/PageRecord.vue'),
    },
    {
      path: 'SchoolAnnouncementContent',
      name: 'SchoolAnnouncementContent',
      component: () => import('@/views/php/AdministrativeMs/SchoolAnnouncementContent/PageRecord.vue'),
    },
    {
      path: 'AllianceAnnouncement',
      name: 'AllianceAnnouncement',
      component: () => import('@/views/php/AdministrativeMs/AllianceAnnouncement/PageRecord.vue'),
    },
    {
      path: 'SchoolSectionalTask',
      name: 'SchoolSectionalTask',
      component: () => import('@/views/php/AdministrativeMs/SchoolSectionalTask/PageRecord.vue'),
    },
    {
      path: 'SchoolSectionalPolicy',
      name: 'SchoolSectionalPolicy',
      component: () => import('@/views/php/AdministrativeMs/SchoolSectionalPolicy/PageRecord.vue'),
    },
    {
      path: 'TaskList',
      name: 'TaskList',
      component: () => import('@/views/php/AdministrativeMs/TaskList/PageRecord.vue'),
    },
    {
      path: 'SchoolSectionalGradePolicy',
      name: 'SchoolSectionalGradePolicy',
      component: () => import('@/views/php/AdministrativeMs/SchoolSectionalGradePolicy/PageRecord.vue'),
    },
    {
      path: 'SchoolSectionalSubjectPolicy',
      name: 'SchoolSectionalSubjectPolicy',
      component: () => import('@/views/php/AdministrativeMs/SchoolSectionalSubjectPolicy/PageRecord.vue'),
    },
    {
      path: 'SchoolSubjectEditionPolicy',
      name: 'SchoolSubjectEditionPolicy',
      component: () => import('@/views/php/AdministrativeMs/SchoolSubjectEditionPolicy/PageRecord.vue'),
    },
    {
      path: 'SchoolSemsterSubjectPolicy',
      name: 'SchoolSemsterSubjectPolicy',
      component: () => import('@/views/php/AdministrativeMs/SchoolSemsterSubjectPolicy/PageRecord.vue'),
    },
    {
      path: 'AllianceSectionTest',
      name: 'AllianceSectionTest',
      component: () => import('@/views/php/AdministrativeMs/AllianceSectionTest/PageRecord.vue'),
    },
    {
      path: 'CourseVersion',
      name: 'CourseVersion',
      component: () => import('@/views/php/AdministrativeMs/CourseVersion/PageRecord.vue'),
    },
    {
      path: 'ClassMs',
      name: 'ClassMs',
      component: () => import('@/views/php/AdministrativeMs/ClassMs/PageRecord.vue'),
    },
    {
      path: 'MessageMs',
      name: 'MessageMs',
      component: () => import('@/views/php/AdministrativeMs/MessageMs/PageRecord.vue'),
    },
    {
      path: 'AnnouncementMs',
      name: 'AnnouncementMs',
      component: () => import('@/views/php/AdministrativeMs/AnnouncementMs/PageRecord.vue'),
    },
    {
      path: 'ClassInformation',
      name: 'ClassInformation',
      component: () => import('@/views/php/AdministrativeMs/ClassInformation/PageRecord.vue'),
    },
    {
      path: 'ClassStudents',
      name: 'ClassStudents',
      component: () => import('@/views/php/AdministrativeMs/ClassStudents/PageRecord.vue'),
    },
    {
      path: 'ClassScore',
      name: 'ClassScore',
      component: () => import('@/views/php/AdministrativeMs/ClassScore/PageRecord.vue'),
    },
    {
      path: 'ClassAllScore',
      name: 'ClassAllScore',
      component: () => import('@/views/php/AdministrativeMs/ClassAllScore/PageRecord.vue'),
    },
    {
      path: 'ClassSingleScore',
      name: 'ClassSingleScore',
      component: () => import('@/views/php/AdministrativeMs/ClassSingleScore/PageRecord.vue'),
    },
    {
      path: 'AnalysisMs',
      name: 'AnalysisMs',
      component: () => import('@/views/php/AdministrativeMs/AnalysisMs/PageRecord.vue'),
    },
    {
      path: 'ScoreExamPaperReview',
      name: 'ScoreExamPaperReview',
      component: () => import('@/views/php/AdministrativeMs/ScoreExamPaperReview/PageRecord.vue'),
    },
    {
      path: 'ScoreExamPaperReviewCombine',
      name: 'ScoreExamPaperReviewCombine',
      component: () => import('@/views/php/AdministrativeMs/ScoreExamPaperReviewCombine/PageRecord.vue'),
    },
    {
      path: 'ScoreClassExamPaperReview',
      name: 'ScoreClassExamPaperReview',
      component: () => import('@/views/php/AdministrativeMs/ScoreClassExamPaperReview/PageRecord.vue'),
    },
    {
      path: 'ScoreClassExamPaperReviewCombine',
      name: 'ScoreClassExamPaperReviewCombine',
      component: () => import('@/views/php/AdministrativeMs/ScoreClassExamPaperReviewCombine/PageRecord.vue'),
    },
    {
      path: 'ClassExamPaperScore',
      name: 'ClassExamPaperScore',
      component: () => import('@/views/php/AdministrativeMs/ClassExamPaperScore/PageRecord.vue'),
    },
    {
      path: 'PlatformGuide',
      name: 'PlatformGuide',
      component: () => import('@/views/php/AdministrativeMs/PlatformGuide/PageRecord.vue'),
    },
    /*****************代理人相關頁面 START********************/
    {
      path: 'AgentAssign',
      name: 'AgentAssign',
      component: () => import('@/views/php/AdministrativeMs/AgentAssign/PageRecord.vue'),
    },
    {
      path: 'SchoolSectionalTaskAssign',
      name: 'SchoolSectionalTaskAssign',
      component: () => import('@/views/php/AdministrativeMs/SchoolSectionalTaskAssign/PageRecord.vue'),
    },
    {
      path: 'ExamPaperAssign',
      name: 'ExamPaperAssign',
      component: () => import('@/views/php/AdministrativeMs/ExamPaperAssign/PageRecord.vue'),
    },
    /*****************代理人相關頁面 END**********************/


    
  ]
};

export default AdministrativeMs;
