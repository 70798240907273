// 第二層 ROUTER 
// 系統管理
let KnowledgeArchitectureMs = {
  path: '/SsoMs',
  redirect: '/SsoMs/AdminRoles',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'SsoAccountMs',
      name: 'SsoAccountMs',
      component: () => import('@/views/php/SsoMs/SsoAccountMs/PageRecord.vue'),
    },
    {
      path: 'SsoIdentityMs',
      name: 'SsoIdentityMs',
      component: () => import('@/views/php/SsoMs/SsoIdentityMs/PageRecord.vue'),
    },

  ]
};
  
export default KnowledgeArchitectureMs;
  