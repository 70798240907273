/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
//import { useRouter, useRoute } from 'vue-router';
// init oltConsole
export class oltApiError {
  constructor() {
    //const oltConsole = new oce() ;
    oltConsole.log('oltApiError!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  // 記錄 之後 想要回來的 地方 ， 例如 登入後回到強制登入頁
  chkError(err, swal, t) {
    //console.log('chkNetworkError :  ', err);
    //console.log('chkNetworkError Response :  ', err.response);
    /***
     *  如果有載入網路的動畫 強迫關閉
     */
    let loader = document.getElementsByClassName('vld-overlay');

    if (loader.length != 0) {
      loader[0].style.display = 'none';
    }

    let status = err.response.status.toString();


    //const router = useRouter();
    //const route = useRoute();

    if(status == '401'){

      //console.log('401ERROR');
      if(swal.isVisible() == false){
        swal.fire({
          icon:'error',
          html:'您沒有權限使用本功能',
          confirmButtonText:t('messages.confirm_button'),
          //cancelButtonText:t('messages.cancel_button'),
          showConfirmButton: true,
          //showCancelButton: true,
        }).then( result => {
          if(result.value){
            //router.go(-1) ; 
          }
        });
      }

    }


    //console.log('i18n : ', t);
    if (err.response) {
      //console.log('Error Response :  ' , err.response );
      //console.log('Error Response s1 :  ' , err.response.status );

      let status = err.response.status.toString();
      //console.log('Error Response s2 :  ' , status );

      // null hot match
      if (status.match(/^5/) != null) {
        // eslint-disable-next-line no-lonely-if
        if(swal.isVisible() == false){
          if (t) {
            this.alertMsg(swal, t('messages.network_err'));
          } else {
            this.alertMsg(swal, '網路連線錯誤!');
          }
        }
      }
    } 
    else {
      // eslint-disable-next-line no-lonely-if
      
      if(swal.isVisible() == false){
        if (t) {
          this.alertMsg(swal, t('messages.network_err'));
        } else {
          this.alertMsg(swal, '網路連線錯誤!');
        }
      }
    }
  }

  alertMsg(swal, msg) {
    swal.fire({
      icon: 'error',
      html: msg
      //showConfirmButton: false,
      //buttonsStyling: true,
      //confirmButtonClass: 'md-button md-success',
      //confirmButtonText:this.t('messages.confirm_button'),
    });
  }

  convertErrorMessageToString(err) {
    let text = 'ErrorMessage';

    if (err != null && err.response != null && err.response.data != null) {
      if (err.response.data.error != null) {
        text = String(err.response.data.error);
      } else if (err.response.data.message != null) {
        text = String(err.response.data.message);
      } else {
        for (const [key, value] of Object.entries(err.response.data)) {
          text += `${key}` + ' : ' + `${value}` + '<br>';
        }
      }
    }
    return text;
  }
}
