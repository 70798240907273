<template>
  <SideNav />
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ps ps--active-y">
    <NavBar />
    <router-view v-slot="{ Component }">
      <transition
        name="fade"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <div class="mx-5 mt-3  mb-5" style="max-height:70vh; border-radius:.75rem; overflow: hidden;">
      <img
      alt="Image placeholder"
        class="w-100"
        src="../assets/img/bookexam.jpg"
      >

  </div>
    <Footer />
  </main>
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { config } from '@/include/config';
import {
  h,
  ref,
  toRefs,
  reactive,
  watch,
  inject,
  onMounted,
  onBeforeUpdate,
  onUpdated
} from 'vue';
import { oltConsole, oAA , oAuth, oApiError , oAdminFrame } from '@/lib/oltModule/initAdminVue3';
//import Header from '@/components/Header.vue';
import { SideNav , NavBar , Settings , Footer } from '@/components/Frame';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'App',
  components: {
    SideNav,
    NavBar,
    // Settings,
    Footer,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    onMounted(async () => {
      chkLogin();
    });
    // Reload MDInput End

    onUpdated(async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals 
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    }) ;

    function gotoPage(page){
      if(router.currentRoute.value.name == page) { return; }
      router.push(page);
    }

    function chkLogin(){
      let isLogin = oAuth.isLogin(store);

      if(!isLogin){
        router.push({ path: '/AuthPage/SignIn' });
      }
    }

    return {
      hiddenHeader:false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: '',
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo:{coin:0 , point:0},
      token:null,
      // function
      gotoPage,
      chkLogin,
    };
  },
  created() {
  },
  mounted() {
    oAdminFrame.initMD();
  },
  methods: {
  }
};
</script>