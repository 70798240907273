
// 第二層 ROUTER
let ExamUploadMs = {
  path: '/ExamUploadMs',
  redirect: '/ExamUploadMs/EUExam',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'EUExam',
      name: 'EUExam',
      component: () => import('@/views/php/ExamUploadMs/EUExam/PageRecord.vue'),
    },
    /*
      {
        path: 'EUExamGroup',
        name: 'EUExamGroup',
        component: () => import('@/views/php/ExamUploadMs/EUExamGroup/PageRecord.vue'),
      },
      */
    {
      path: 'EUExamImportAllow',
      name: 'EUExamImportAllow',
      component: () => import('@/views/php/ExamUploadMs/EUExamImportAllow/PageRecord.vue'),
    },
    {
      path: 'AddEUExam',
      name: 'AddEUExam',
      component: () => import('@/views/php/ExamUploadMs/AddEUExam/PageRecord.vue'),
    },
    {
      path: 'EditEUExam',
      name: 'EditEUExam',
      component: () => import('@/views/php/ExamUploadMs/EditEUExam/PageRecord.vue'),
    },
    {
      path: 'EUExamReview',
      name: 'EUExamReview',
      component: () => import('@/views/php/ExamUploadMs/EUExamReview/PageRecord.vue'),
    },
    {
      path: 'ReviewEUExam',
      name: 'ReviewEUExam',
      component: () => import('@/views/php/ExamUploadMs/ReviewEUExam/PageRecord.vue'),
    },
    {
      path: 'Exam',
      name: 'Exam',
      component: () => import('@/views/php/ExamUploadMs/Exam/PageRecord.vue'),
    },
    {
      path: 'ReviewExam',
      name: 'ReviewExam',
      component: () => import('@/views/php/ExamUploadMs/ReviewExam/PageRecord.vue'),
    },
    {
      path: 'EUExamToExamProgressBar',
      name: 'EUExamToExamProgressBar',
      component: () => import('@/views/php/ExamUploadMs/EUExamToExamProgressBar/PageRecord.vue'),
    },
    { // 考卷命題管理 (考卷規則)
      path: 'ExamPaperRule',
      name: 'ExamPaperRule',
      component: () => import('@/views/php/ExamUploadMs/ExamPaperRule/PageRecord.vue'),
    },
    { // 考卷規則、難易度管理
      path: 'ExamPaperRuleDifficultyRt',
      name: 'ExamPaperRuleDifficultyRt',
      component: () => import('@/views/php/ExamUploadMs/ExamPaperRuleDifficultyRt/PageRecord.vue'),
    },
    { // 上架區，試題審查紀錄
      path: 'EURExamReview',
      name: 'EURExamReview',
      component: () => import('@/views/php/ExamUploadMs/EURExamReview/PageRecord.vue'),
    },
    { // 上架區，試題審查紀錄細項
      path: 'EURExamReviewItem',
      name: 'EURExamReviewItem',
      component: () => import('@/views/php/ExamUploadMs/EURExamReviewItem/PageRecord.vue'),
    },
    
  ]
};
  
export default ExamUploadMs;
  