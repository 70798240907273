/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  sysEduSchoolRt: oltEncrypt.storeDecodeObject( sessionStorage.getItem('sysEduSchoolRt')) ,
};

/*
mutations 必須是同步執行，而 actions 則可以異步執行。
*/
export const actions = {
  // 使用 Action 寫入 l
  setSysEduSchoolRt({ commit }, obj) {
    //console.log('action  : ' , obj);
    commit('setSysEduSchoolRt', obj);
  },
  cleanSysEduSchoolRt({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanSysEduSchoolRt');
  },

};

/*
Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。

mutation必定是同步函數，沒有例外
mutation是vuex中唯一可以改動state的方法
也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程

發出一個action(dispatch an action)
接收到action後，執行對應的mutation (commit a mutation)
透過mutation更改state
*/
export const mutations = {
  setSysEduSchoolRt(state, obj) {
    sessionStorage.setItem('sysEduSchoolRt', oltEncrypt.storeEncodeObject(obj));
    state.sysEduSchoolRt = obj;
  },
  cleanSysEduSchoolRt(state) {
    sessionStorage.removeItem('sysEduSchoolRt');
    state.sysEduSchoolRt = null;
  },

};

export const getters = {
  //isAuthenticated: state => Boolean(state.user) || Boolean(sessionStorage.getItem('user')),
  isSysEduSchoolRt(state) {
    if (state.sysEduSchoolRt) {
      return true;
    }
    return false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
