<template>
  <template v-if="dev.showApiTitle">
    <li class="nav-item text-center">
      PHP API
    </li>
  </template>

  <li
    class="nav-item"
    v-for="ritem in rtAllRecord.AdminModules"
    :key="ritem"
  >
    <!-- Yves modify SBDW5-966 -->
    <template v-if="ritem.view == true">
      <a
        data-bs-toggle="collapse"
        :href="'#' + ritem.code"
        class="nav-link text-white"
        :class="{ active: ritem.code == routerPathArr[1] }"
        aria-controls="ecommerceExamples"
        role="button"
        :aria-expanded="ritem.code == routerPathArr[1]"
      >
        <i class="material-icons-round">
          {{ ritem.icon }}
        </i>
        <span class="nav-link-text ms-2 ps-1">
          {{ ritem.name }}
        </span>
      </a>
    </template>
    <div
      class="collapse"
      :class="{ show: ritem.code == routerPathArr[1] }"
      :id="ritem.code"
    >
      <template 
        v-for="item in recordData"
        :key="item"
      >
        <template
          v-if="item.layer == 1 &&
            item.node == 0 && 
            item.view == 1 && 
            item.mid == ritem.mid"
        >
          <ul class="nav">
            <li class="nav-item">
              <template
                v-if="
                  item.layer == 1 &&
                    item.node == 0 &&
                    item.nodeNum == 0 &&
                    item.view == 1 &&
                    item.mid == ritem.mid
                "
              >
                <li
                  class="nav-item"
                  :class="{ active: checkPage(item.code,routerPathArr[2]) }"
                >
                  <!--
                  <a
                    href="javascript:;"
                    @click="goToPage('/' + ritem.code + '/' + item.code)"
                    class="nav-link text-white"
                    :class="{ active: item.code == routerPathArr[2] }"
                  >
                    <i
                      class="material-icons-round"
                      style="font-size: 19px;"
                    >
                      {{ item.icon }}
                    </i>
                    <span class="sidenav-normal ms-2 ps-1">
                      {{ item.name }}
                    </span>
                  </a>
                  -->
                  <router-link
                    :to="'/' + ritem.code + '/' + item.code"
                    class="nav-link text-white"
                    :class="{ active: checkPage(item.code,routerPathArr[2]) }"
                    @click="goToPage('/' + ritem.code + '/' + item.code)"
                  >
                    <i
                      class="material-icons-round ps-3"
                      style="font-size: 19px;"
                    >
                      {{ item.icon }}
                    </i>
                    <span class="sidenav-normal ms-2 ps-1">
                      {{ item.name }}
                    </span>
                  </router-link>
                </li>
              </template>
              <template
                v-else-if="
                  item.layer == 1 &&
                    item.node == 0 &&
                    item.nodeNum > 0 &&
                    item.view == 1 &&
                    item.mid == ritem.mid
                "
              >
                <a
                  data-bs-toggle="collapse"
                  :href="'#' + item.code"
                  class="nav-link text-white"
                  :class="{ active: checkPage(item.code,routerPathArr[2])}"
                  :aria-expanded="checkPage(item.code,routerPathArr[2])"
                >
                  <i
                    class="material-icons-round ps-3"
                    style="font-size: 19px;"
                  >
                    {{ item.icon }}
                  </i>
                  <span class="sidenav-normal ms-2 ps-1">
                    {{ item.name }}
                    <b class="caret" /></span>
                </a>
                <div
                  class="collapse"
                  :id="item.code"
                  :class="{ show: checkLayer2(item.fid,routerPathArr[2]) }"
                >
                  <ul class="nav nav-sm flex-column">
                    <template 
                      v-for="item2 in recordData"
                      :key="item2"
                    >
                      <template
                        v-if="item2.node == item.fid && 
                          item2.mid == ritem.mid && 
                          item2.view == 1"
                      >
                        <li
                          class="nav-item"
                          :class="{ active: item2.code == routerPathArr[2] }"
                        >
                          <!--
                          <a
                            class="nav-link text-white"
                            :class="{ active: item2.code == routerPathArr[2] }"
                            href="javascript:;"
                            @click="goToPage('/' + ritem.code + '/' + item2.code)"
                          >
                            <i
                              class="material-icons-round"
                              style="font-size: 19px;"
                            >
                              {{ item2.icon }}
                            </i>
                            <span class="sidenav-normal ms-2 ps-1">
                              {{ item2.name }}
                            </span>
                          </a>-->
                          <router-link
                            :to="`/${ritem.code}/${item2.code}`"
                            class="nav-link text-white"
                            :class="{ active: item2.code === routerPathArr[2] }"
                            @click="goToPage('/' + ritem.code + '/' + item2.code)"
                          >
                            <i
                              class="material-icons-round ps-3 ms-3"
                              style="font-size: 19px;"
                            >
                              {{ item2.icon }}
                            </i>
                            <span class="sidenav-normal ms-2 ps-1">
                              {{ item2.name }}
                            </span>
                          </router-link>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
              </template>
            </li>
          </ul>
        </template>
      </template>
    </div>
  </li>
  
  <!-- Yves add SBDW5-917 start-->
  <template v-if="showCloseAllMenu == true">
    <hr class="horizontal light mt-0">
    <li class="nav-item">
      <a
        class="nav-link test-white"
        @click.stop="closeAllMenu()"
      >
        <i class="material-icons-round">
          settings
        </i>
        <span class="nav-link-text ms-2 ps-1">
          全 部 收 攏
        </span>
      </a>
    </li>
  </template>
  <!-- Yves add SBDW5-917 end-->
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive, toRefs , watch, inject, onMounted, onUpdated } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {
  config,
  dev,
  oltConsole,
  oAA,
  oAuth,
  oApiError,
  oAdminFrame,
  oUserInfo,
  oUrl,
  oEduSysSchoolRt
} from '@/lib/oltModule/initAdminVue3';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

//import Loading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'App',
  components: {
    //Loading,
  },
  props:{
    newPage: {
      type: String ,
      required:true
    },  
  },
  emits: ['changePage','reloadPage'],
  setup(props,{emit}) {
    /**
     *  swal start
     */
    const swal = inject('$swal');
    /**
     *  swal end
     */

    /**
     *  i18n Start
     */
    const { t, locale } = useI18n({
      useScope: 'global' // 必需設定 SCOPE 才能覆蓋設定
    });

    locale.value = 'zh-TW';
    /**
     *  i18n end
     */

    const store = useStore();

    /**
     *  Record Page Start
     */
    const apiName = ref('admin_funs');
    const rtTableName1 = ref('admin_modules');
    const rtTableName2 = ref('admin_role_user_rt_join');
    const rtTableName3 = ref('admin_role_fun_rt_join');

    const pkyKey = ref('rid');
    //const tableName1 = ref(apiName.value.charAt(0).toLowerCase() + apiName.value.slice(1) );
    const tableName1 = apiName.value;
    const isLoading = ref(true);
    const pageRecordColumn = ref({});
    const pageNumber = ref(1);
    const pageFilter = ref('');
    const isUserRole = ref(0);
    const userProfile = ref();
    const userSchoolProfile = ref(null);
    
    userProfile.value = oAuth.getUserProfile(store);
    userSchoolProfile.value = oEduSysSchoolRt.getEduSysSchoolRt(store);

    const rtAllRecord = ref({});
    const pageRecord = ref({});
    const recordData = ref({});
    const rtPageRecord = ref({});
    const { newPage } = toRefs(props);

    watch(newPage, (newValue, oldValue) => {
      // console.log('performanceArray',performanceArray.value);
      if(newValue != ''){
        routerPathArr.value = newValue.split('/');
      }
      
      // mergeColume();
    }) ;

    // pageSort Start
    // use RecordColumn component
    const pageSort = ref('seq');
    const pageSortBy = ref('asc');
    // pageSort End

    // pageSize start
    const pageSize = ref(5);
    // pageFilter End

    onMounted(async () => {
      // console.log('PHPNav onMounted');
      let adminPermissions = oUserInfo.getAdminPermissions(store);
      let isLogin = oAuth.isLogin(store);

      // console.log('adminPermissions',adminPermissions);

      // console.log('userProfile',userProfile.value);
      // console.log('userSchoolProfile',userSchoolProfile.value);
      

      if(adminPermissions){
        // console.log('PHPNav onMounted adminPermissions' , adminPermissions);
        drawRecord(adminPermissions);
      }
      else if(isLogin){
        //console.log('PHPNav onMounted isLogin');
        drawPageRecord();
      }
    });
    // Reload MDInput End

    onUpdated(async () => {
      //console.log('PHPNav onUpdated');
    }) ;

    function drawPageRecord() {
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: oAuth.getToken(store), // token key from veex store
        // Debug Mode
        apiGetPageDebug: false, // API getPageRecord 除錯模式
        apiGetRtAllRecordDebug: false,
        apiGetRtPageRecordDebug: false,
        // API Filter
        apiName: apiName.value,
        pageNumber: pageNumber.value, // int32
        pageSize: pageSize.value, // int 32
        pageSort: pageSort.value, // string
        pageSortBy: pageSortBy.value, // string
        /**
         *  customFilter
         *  客制化 .net core 的 filter
         *  預設 是 false
         *  如果啟動 pageSearch 本來是 帶 JSON 字串 會將欄位全部轉換成 API 的參數 帶出去
         *  EX ： api/?pageSearch={fid:1,name:'test'} => api/？fid=1&name=test&pageSearch={fid:1,name:'test'}
         * */
        customFilter: false,
        pageFilter: pageFilter.value, // string
        pageSearch: {
          switch: [true]
          //name:pageSearchName.value,
          //code:pageSearchCode.value,
          //description:pageSearchDescription.value,
          //switch:pageSearchSwitch.value
        }, // json format
        pageGroup: '', // string
        pageGroupBy: '', // string
        pageMode: false, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt
        rtSet: {
          AdminModules: {
            type: 'all',
            //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
            apiName: rtTableName1.value,
            //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSearch: { switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: 'seq', // string
            pageSortBy: 'asc' // string
          },
          AdminRoleUserRtJoin: {
            type: 'all',
            //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
            apiName: rtTableName2.value,
            //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSearch: { switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: 'seq', // string
            pageSortBy: 'asc', // string
            pageGroupBy: 'arurid',
          },
          SsoEdusysSchoolRoleUserRt: {
            type: 'all',
            //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
            apiName: 'sso_edusys_school_role_user_rt',
            //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSearch: { get_private: ['Y'],switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: 'seq', // string
            pageSortBy: 'asc', // string
          },
        },
        queueSpeed: 1 // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
      };

      if(userSchoolProfile.value && userSchoolProfile.value.sesrurid){
        if(userSchoolProfile.value.sesrurid != null){
          if(userProfile.value && userProfile.value.role){
            for (const [prk, prv] of Object.entries(userProfile.value.role)) {
              if(userSchoolProfile.value.sesrurid == prv.sesrurid){
                isUserRole.value = prv.rid;
                apiParam.rtSet.AdminRoleFunRtJoin = {
                  type: 'all',
                  //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
                  apiName: rtTableName3.value,
                  //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
                  pageSearch: { rid:[prv.rid],switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
                  pageSort: 'seq', // string
                  pageSortBy: 'asc', // string
                  pageGroupBy: 'arfrid',
                };
              }
            }
          }
        }else if(userProfile.value && userProfile.value.role){
          for (const [prk, prv] of Object.entries(userProfile.value.role)) {
            if(prv.sesrurid == null){
              isUserRole.value = prv.rid;
              apiParam.rtSet.AdminRoleFunRtJoin = {
                type: 'all',
                //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
                apiName: rtTableName3.value,
                //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
                pageSearch: { rid:[prv.rid],switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
                pageSort: 'seq', // string
                pageSortBy: 'asc', // string
                pageGroupBy: 'arfrid',
              };
            }
          }
        }
      }else if(userProfile.value && userProfile.value.role){
        let check = false;

        for (const [prk, prv] of Object.entries(userProfile.value.role)) {
          if(prv.default_edusys != null && prv.default_edusys == 1){
            check = true;
            isUserRole.value = prv.rid;
            apiParam.rtSet.AdminRoleFunRtJoin = {
              type: 'all',
              //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
              apiName: rtTableName3.value,
              //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
              pageSearch: { rid:[prv.rid],switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
              pageSort: 'seq', // string
              pageSortBy: 'asc', // string
              pageGroupBy: 'arfrid',
            };
          }
        }
        if(check == false){
          isUserRole.value = userProfile.value.role[0].rid;
          apiParam.rtSet.AdminRoleFunRtJoin = {
            type: 'all',
            //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
            apiName: rtTableName3.value,
            //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSearch: { rid:[userProfile.value.role[0].rid],switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: 'seq', // string
            pageSortBy: 'asc', // string
            pageGroupBy: 'arfrid',
          };
        }
      }else{
        apiParam.rtSet.AdminRoleFunRtJoin = {
          type: 'all',
          //apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
          apiName: rtTableName3.value,
          //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
          pageSearch: { switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
          pageSort: 'seq', // string
          pageSortBy: 'asc', // string
          pageGroupBy: 'arfrid',
        };
      }

      oAA
        .queueGetAllVue3(apiParam)
        .then((res) => {
          // console.log('res : ',res);
          rtAllRecord.value = res.rtAllRecord;
          pageRecord.value = res.pageRecord;
          recordData.value = getNodeNumRecord();
          rtPageRecord.value = res.rtPageRecord;

          mergeData();

          isLoading.value = false;

          let data = {};

          /*
          data.AdminModules = res.rtAllRecord.AdminModules;
          data.AdminRoleFunRtJoin = res.rtAllRecord.AdminRoleFunRtJoin;
          data.AdminRoleUserRtJoin = res.rtAllRecord.AdminRoleUserRtJoin;
          data.AdminFuns = res.pageRecord;
          */

          /*
           * 權限系統檢查使用，不得任意變更
           * Yves modify SBDW5-966
           */
          data.pageRecord = pageRecord.value;
          data.rtAllRecord = rtAllRecord.value;
          data.rtAllRecord.AdminFuns = recordData.value;
          data.rtPageRecord = rtPageRecord.value;
          data.recordData = recordData.value;
          //console.log('setAdminPermissions data : ', data);

          // admin permissions save to vuex
          store.dispatch('setAdminPermissions', data);
          emit('reloadPage');
          //console.log('store : ', store);

          //let data2 = oUserInfo.getAdminPermissions(store);

          //console.log('data2 : ', data2.AdminRoleUserRtJoin);

          //let data3 = oUserInfo.getAdminRoleFunRtByFunsCode(store, 'AdminRoleFunRt');

          //console.log('data3 : ', data3);

          //oUserInfo.cleanAdminPermissions(store);
          //store.dispatch('cleanAdminPermissions');

          //console.log('store : ', store);
        })
        .catch((err) => {
          oApiError.chkError(err, swal, t);
          //console.log(err);
        });
    }

    //console.log('apiParam :' , apiParam);
    //console.log('recordData :' , recordData);

    function getNodeNumRecord() {
      if(userProfile.value && userProfile.value.role){
        if(isUserRole.value == 5||isUserRole.value == 4||isUserRole.value == 3){
          if (pageRecord.value) {
            for (const [prk, prv] of Object.entries(pageRecord.value)) {
              if(prv.code == 'FieldMs'){
                prv.name = '領域資料';
                prv.description = '領域資料';
              }
              if(prv.code == 'LearningPerformance'){
                prv.name = '學習表現資料';
                prv.description = '學習表現資料';
              }
              if(prv.code == 'LearningContent'){
                prv.name = '學習內容資料';
                prv.description = '學習內容資料';
              }
              if(prv.code == 'Course'){
                prv.name = '課程資料';
                prv.description = '課程資料';
              }
              if(isUserRole.value == 5){
                if(prv.code == 'ClassMs'){
                  prv.name = '班級資料';
                  prv.description = '班級資料';
                }
                if(prv.code == 'SectionMs'){
                  prv.name = '段考資料';
                  prv.description = '段考資料';
                }
                if(prv.code == 'AnalysisMs'){
                  prv.name = '答題分析資料';
                  prv.description = '答題分析資料';
                }
              }
            }
          }
        }
      }
      // console.log('pageRecord : ', pageRecord.value);
      if (pageRecord.value) {
        for (const [prk, prv] of Object.entries(pageRecord.value)) {
          if (prv.node == 0) {
            //console.log('pageRecord root node : ', prk, prv);
            let nodeNum = 0;

            for (const [pprk, pprv] of Object.entries(pageRecord.value)) {
              if (pprv.node != 0 && pprv.node == prv.fid) {
                //console.log('pageRecord sub node : ', pprk, pprv);
                nodeNum++;
              }
              //console.log('pageRecord sub node : ' , pprk , pprv , nodeNum);
            }
            pageRecord.value[prk].nodeNum = nodeNum;
            //console.log('pageRecord data : ' , pageRecord.value[prk] );
            //console.log('pageRecord root node : ' , prk , prv , nodeNum);
          }

          // permissions
          pageRecord.value[prk].view = 0;
          if (rtAllRecord.value != null && rtAllRecord.value.AdminRoleFunRtJoin != null) {
            for (const [rak, rav] of Object.entries(rtAllRecord.value.AdminRoleFunRtJoin)) {
              if (rav.fid != 0 && prv.fid == rav.fid) {
                pageRecord.value[prk].view = rav.view;
                break;
              }
            }
          }
        }
      }
      //console.log('pageRecord : ', pageRecord.value);

      return pageRecord.value;
    }

    function mergeData(){
      if(userProfile.value && userProfile.value.role){
        if(isUserRole.value == 5||isUserRole.value == 4||isUserRole.value == 3){
          if (rtAllRecord.value != null) {
            if(rtAllRecord.value.AdminModules != null){
              for (const [k, v] of Object.entries(rtAllRecord.value.AdminModules)) {
                if(v.code == 'KnowledgeArchitectureMs'){
                  rtAllRecord.value.AdminModules[k].name = '課綱資料';
                  rtAllRecord.value.AdminModules[k].description = '課綱資料';
                }
              }
            }
          }
        }
      }
      if (rtAllRecord.value != null) {
        // 檢查是否要顯示 Modules，Yves modify SBDW5-966
        if(rtAllRecord.value.AdminModules != null){
          for (const [k, v] of Object.entries(rtAllRecord.value.AdminModules)) {
            rtAllRecord.value.AdminModules[k].view = false;
            if (recordData.value != null) {
              for (const [rk, rv] of Object.entries(recordData.value)) {
                if(rv == null || rv.mid == null || v.mid != rv.mid || rv.view != 1|| rv.layer != 1){
                  continue;
                }
                rtAllRecord.value.AdminModules[k].view = true;
                break;
              }
            }
          }
        }
      }
      //console.log('rtAllRecord : ', rtAllRecord.value);
    }

    function drawRecord(data){
      //console.log('drawRecord data : ', data);
      if(data != null){
        if(data.pageRecord != null){
          pageRecord.value = data.pageRecord;
        }
        if(data.rtAllRecord != null){
          rtAllRecord.value = data.rtAllRecord;
        }
        if(data.rtPageRecord != null){
          rtPageRecord.value = data.rtPageRecord;
        }
        if(data.recordData != null){
          recordData.value = data.recordData;
        }
      }

      //console.log('pageRecord : ', pageRecord.value);
      //console.log('rtAllRecord : ', rtAllRecord.value);
      //console.log('rtPageRecord : ', rtPageRecord.value);
      //console.log('recordData : ', recordData.value);

      isLoading.value = false;
    }

    function checkLayer2(fid,page){
      // console.log('fid',fid);
      if(recordData.value){
        for (const [rk, rv] of Object.entries(recordData.value)) {
          if(rv.node == fid && rv.code == page){
            return true;
          }
        }
      }
      return false;

    }
    
    function checkPage(code,page) {
      // console.log('rtAllRecord.value.AdminModules',rtAllRecord.value.AdminModules);
      // console.log('Record.value',recordData.value);
      if(code === page){
        return true;
      }else if(code === 'ClassMs' && (page === 'ClassStudents' || page === 'ClassScore' || page === 'ClassAllScore' || page === 'ClassSingleScore' || page === 'ClassInformation')){
        return true;
      }
      return false;
    }

    function timeToDateTime(time) {
      //console.log(moment(time).format('YYYY/MM/DD'));
      return moment(time).format('YYYY/MM/DD HH:mm:ss');
    }
    /**
     *  Record Page End
     */

    const router = useRouter();
    const route = useRoute();

    //console.log('router : ' , router.currentRoute.value.path.split('/'));
    //console.log('router : ' , router.currentRoute.value);
    //const routerPathArr = router.currentRoute.value.path.split('/') ;
    const routerPathArr = ref('');

    //routerPathArr.value = '/SysMs/AdminRoles'.split('/') ;
    routerPathArr.value = router.currentRoute.value.path.split('/');
    function goToPage(page) {
      emit('changePage',page);
      routerPathArr.value = page.split('/');
      
      // console.log('routerPathArr.value',routerPathArr.value);
      if(page == '/KnowledgeArchitectureMs/Course'){
        let goToUrl = { path: page , query : {}} ; 

        oUrl.routerPushToUrl(router,store,goToUrl);
      }else{
        router.push({ path: page });
      }
      
    }

    /**
     *  initAdmion.js Start
     *  修改新增資料 在 刷新時重新初始化 material dashboard 的 js 設定
     */
    // Reload MDInput Start
    const initInput = async () => {
      // eslint-disable-next-line no-undef
      await oAdminFrame.initMdInput();
    };

    onMounted(initInput);
    // Reload MDInput End

    onUpdated(async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    });
    /**
     *  initAdmion.js End
     */

    const showCloseAllMenu = ref(true); // Yves add SBDW5-917

    /*
     * 關閉所有 Menu 功能
     * Yves add SBDW5-917
     */
    function closeAllMenu(){
      //console.log('closeAllMenu');

      // 取得展開的 Menu
      let elements = document.getElementsByClassName('collapse show');

      if(elements != null && elements.length > 0){
        for (let i = (elements.length - 1); i >= 0; i--) {
          //console.log('elements['+i+'] : ');
          //console.log(elements[i]);
          elements[i].classList.remove('show');
        }
      }

      // 取得所有抽屜
      elements = document.getElementsByClassName('nav-link text-white');
      if(elements != null && elements.length > 0){
        for (let i = (elements.length - 1); i >= 0; i--) {
          //console.log('elements['+i+'] : ');
          //console.log(elements[i]);
          let temp = elements[i].getAttribute('aria-expanded');

          // 將展開的 ICON，替換成關閉
          if(temp == 'true'){
            //console.log('temp : ', temp);
            elements[i].setAttribute('aria-expanded', 'false');
          }
        }
      }
      
    }

    return {
      dev,
      config,
      // moment
      moment,
      // i18n
      t,
      locale,
      // record page
      pageRecordColumn,
      pageNumber,
      pageSort,
      pageSortBy,
      pageSize,
      pageFilter,
      rtAllRecord,
      pageRecord,
      recordData,
      rtPageRecord,
      // Loading
      isLoading,
      fullPage: true,
      // const data
      token: null,
      apiName,
      pkyKey,
      tableName1,
      rtTableName1,
      routerPathArr,
      showCloseAllMenu,
      // function
      timeToDateTime,
      goToPage,
      closeAllMenu,
      checkLayer2,
      checkPage,
    };
  }
};
</script>
