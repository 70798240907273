// 第二層 ROUTER 
// 系統管理
let KnowledgeArchitectureMs = {
  path: '/KnowledgeArchitectureMs',
  redirect: '/KnowledgeArchitectureMs/AdminRoles',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'AcademicYears',
      name: 'AcademicYears',
      component: () => import('@/views/php/KnowledgeArchitectureMs/AcademicYears/PageRecord.vue'),
    },
    {
      path: 'AcademicYearsEdit',
      name: 'AcademicYearsEdit',
      component: () => import('@/views/php/KnowledgeArchitectureMs/AcademicYearsEdit/PageRecord.vue'),
    },
    {
      path: 'AcademicYearsCopy',
      name: 'AcademicYearsCopy',
      component: () => import('@/views/php/KnowledgeArchitectureMs/AcademicYearsCopy/PageRecord.vue'),
    },
    {
      path: 'Course',
      name: 'Course',
      component: () => import('@/views/php/KnowledgeArchitectureMs/Course/PageRecord.vue'),
    },
    {
      path: 'LearningPerformance',
      name: 'LearningPerformance',
      component: () => import('@/views/php/KnowledgeArchitectureMs/LearningPerformance/PageRecord.vue'),
    },
    {
      path: 'LearningContent',
      name: 'LearningContent',
      component: () => import('@/views/php/KnowledgeArchitectureMs/LearningContent/PageRecord.vue'),
    },
    {
      path: 'IssueIntegrationMs',
      name: 'IssueIntegrationMs',
      component: () => import('@/views/php/KnowledgeArchitectureMs/IssueIntegrationMs/PageRecord.vue'),
    },
    {
      path: 'KnowledgeArchitecture',
      name: 'KnowledgeArchitecture',
      component: () => import('@/views/php/KnowledgeArchitectureMs/KnowledgeArchitecture/PageRecord.vue'),
    },


  ]
};
  
export default KnowledgeArchitectureMs;
  