/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  aa: oltEncrypt.test(),
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  guest: oltEncrypt.storeDecodeObject(sessionStorage.getItem('admin_guest')),
  uuid: oltEncrypt.storeDecodeText(sessionStorage.getItem('admin_uuid'))
};
/*
  mutations 必須是同步執行，而 actions 則可以異步執行。
  */
export const actions = {
  setGuest({ commit }, guest) {
    commit('setGuest', guest);
  },
  cleanGuest({ commit }) {
    commit('cleanGuest');
  },
  setUUId({ commit }, uuid) {
    commit('setUUId', uuid);
  },
  cleanUUId({ commit }) {
    commit('cleanUUId');
  }
};

/*
  Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。
  
  mutation必定是同步函數，沒有例外
  mutation是vuex中唯一可以改動state的方法
  也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程
  
  發出一個action(dispatch an action)
  接收到action後，執行對應的mutation (commit a mutation)
  透過mutation更改state
  */
export const mutations = {
  setGuest(state, guest) {
    sessionStorage.setItem('admin_guest', oltEncrypt.storeEncodeObject(guest));
    state.guest = guest;
  },
  cleanGuest(state) {
    sessionStorage.removeItem('admin_guest');
    state.guest = null;
  },
  setUUId(state, uuid) {
    sessionStorage.setItem('admin_uuid', oltEncrypt.storeEncodeText(uuid));
    state.uuid = uuid;
  },
  cleanUUId(state) {
    sessionStorage.removeItem('admin_uuid');
    state.uuid = null;
  }
};

export const getters = {
  isGuest(state) {
    if (state.guest) {
      return true;
    }
    return false;
  },
  isUUId(state) {
    if (state.uuid) {
      return true;
    }
    return false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
