/* eslint-disable import/no-unresolved */
import { config, modulesList } from '@/include/config';
import {
  createRouter,
  createWebHistory,
  createWebHashHistory
  //RouteRecordRaw,
} from 'vue-router';
// 第一層 ROUTER
import Home from '@/views/Home.vue';
import SSOAutoLogin from '@/views/SSOAutoLogin.vue';

// 第二層 Router

// 系統管理
import AuthPage from '@/router/AuthPage';

// .net core module
//import DCSysMs from '@/router/dotnet/SysMs';
//import DCFunMs from '@/router/dotnet/FunMs';

// php module
import SysMs from '@/router/php/SysMs';
import SysObjectMs from '@/router/php/SysObjectMs';
import SsoMs from '@/router/php/SsoMs';


import ExamMs from '@/router/php/ExamMs';
import AdministrativeMs from '@/router/php/AdministrativeMs';

//import MessageMs from '@/router/php/MessageMs';
//import HistoryMs from '@/router/php/HistoryMs';
import MemMs from '@/router/php/MemMs';
//import VideoMS from '@/router/php/VideoMS';

//import FeedBackMs from '@/router/php/FeedBackMs';
//import ProductMs from '@/router/php/ProductMs';
//import LessonMs from '@/router/php/LessonMs';
// import LessonYearMs from '@/router/php/LessonYearMs';
//import ShoplineMs from '@/router/php/ShoplineMs';
//import WebMs from '@/router/php/WebMs';
//import OrderMs from '@/router/php/OrderMs';
//import AuditMs from '@/router/php/AuditMs';
//import CurriculumMs from '@/router/php/CurriculumMs';
import ExamUploadMs from '@/router/php/ExamUploadMs';
//import VerifyMs from '@/router/php/VerifyMs';
//import MessageManagementMs from '@/router/php/MessageManagementMs';
//import testMs from '@/router/php/testMs';
//import QuestionMs from '@/router/php/QuestionMs';
//import ErrataMs from '@/router/php/ErrataMs';
//import PlayerMs from '@/router/php/PlayerMs';
//import TestAdminMs from '@/router/php/TestAdminMs';
import TestPolicyMs from '@/router/php/TestPolicyMs';
import SSOAuthPage from '@/router/php/SSOAuthPage';
//import DifficultyLevelMs from '@/router/php/DifficultyLevelMs';
//import LearningDimensionMs from '@/router/php/LearningDimensionMs';
//import ExamResourceMs from '@/router/php/ExamResourceMs';
import KendoTest from '@/router/php/KendoTest';
import AdminUserSelfMs from '@/router/php/AdminUserSelfMs';
import KnowledgeArchitectureMs from '@/router/php/KnowledgeArchitectureMs';
import EducationAdministrativeMs from '@/router/php/EducationAdministrativeMs';

const routes = [
  /*
  {
    path: '/',
    redirect: '/SysMs/AdminRoles',
  },
  */
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/SSOAutoLogin',
    name: 'SSOAutoLogin',
    component: SSOAutoLogin
  },
  AdministrativeMs,
  //DCSysMs,
  //DCFunMs,
  SsoMs,
  SysMs,
  SysObjectMs,
  ExamMs,
  //MessageMs,
  //HistoryMs,
  MemMs,
  //VideoMS,
  //FeedBackMs,
  //ProductMs,
  //LessonMs,
  // LessonYearMs,
  //ShoplineMs,
  //WebMs,
  //OrderMs,
  //AuditMs,
  //CurriculumMs,
  //PlayerMs,
  //testMs,
  ExamUploadMs,
  //VerifyMs,
  //MessageManagementMs,
  //QuestionMs,
  //ErrataMs,
  //TestAdminMs,
  TestPolicyMs,
  //DifficultyLevelMs,
  //LearningDimensionMs,
  //ExamResourceMs,
  KendoTest,
  AdminUserSelfMs,
  KnowledgeArchitectureMs,
  EducationAdministrativeMs,
  {
    // 預覽試卷
    path: '/PrintExamPaper',
    name: 'PrintExamPaper',
    component: () =>
      import('@/views/php/TestAdminMs/PrintExamPaper/PageRecord.vue')
  },
  {
    // 預覽上架區試題
    path: '/ReviewEUExam',
    name: 'ReviewEUExam',
    component: () =>
      import('@/views/php/ExamUploadMs/ReviewEUExam/PageRecord.vue')
  },
  {
    // 預覽試題
    path: '/ReviewExam',
    name: 'ReviewExam',
    component: () =>
      import('@/views/php/ExamUploadMs/ReviewExam/PageRecord.vue')
  }
];

if (modulesList != null) {
  if (modulesList.ssoSignIn != null && modulesList.ssoSignIn == true) {
    routes.push(SSOAuthPage);
  } else {
    routes.push(AuthPage);
  }
} else {
  routes.push(AuthPage);
}

const router = createRouter({
  //history: createWebHashHistory(config.baseUrl),
  //history: createWebHashHistory(),
  //history: createWebHistory(config.baseUrl),
  //history: createWebHistory(),
  history:
    process.env.NODE_ENV === 'production' ?
      createWebHashHistory() :
      createWebHistory(),
  linkActiveClass: 'cur',
  linkExactActiveClass: 'cur',
  routes
});

router.isReady().then(() => {
  //console.log('is Ready!!!!');
});

export default router;
