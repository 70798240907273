export class initOltAdminFrame {
  /* eslint-disable */
  initMD() {
    // sidenav
    if (document.querySelector('.sidenav-toggler')) {
      let sidenavToggler =
        document.getElementsByClassName('sidenav-toggler')[0];
      let sidenavShow = document.getElementsByClassName('g-sidenav-show')[0];
      let toggleNavbarMinimize = document.getElementById('navbarMinimize');

      if (sidenavShow) {
        sidenavToggler.onclick = function () {
          if (!sidenavShow.classList.contains('g-sidenav-hidden')) {
            sidenavShow.classList.remove('g-sidenav-pinned');
            sidenavShow.classList.add('g-sidenav-hidden');
            if (toggleNavbarMinimize) {
              toggleNavbarMinimize.click();
              toggleNavbarMinimize.setAttribute('checked', 'true');
            }
          } else {
            sidenavShow.classList.remove('g-sidenav-hidden');
            sidenavShow.classList.add('g-sidenav-pinned');
            if (toggleNavbarMinimize) {
              toggleNavbarMinimize.click();
              toggleNavbarMinimize.removeAttribute('checked');
            }
          }
        };
      }
    }

    // Toggle Sidenav
    const iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
    const iconSidenav = document.getElementById('iconSidenav');
    const sidenav = document.getElementById('sidenav-main');
    let body = document.getElementsByTagName('body')[0];
    let className = 'g-sidenav-pinned';

    if (iconNavbarSidenav) {
      iconNavbarSidenav.addEventListener('click', toggleSidenav);
    }

    if (iconSidenav) {
      iconSidenav.addEventListener('click', toggleSidenav);
    }

    function toggleSidenav() {
      if (body.classList.contains(className)) {
        body.classList.remove(className);
        setTimeout(function () {
          sidenav.classList.remove('bg-white');
        }, 100);
        sidenav.classList.remove('bg-transparent');
      } else {
        body.classList.add(className);
        sidenav.classList.remove('bg-transparent');
        iconSidenav.classList.remove('d-none');
      }
    }

    // Fixed Plugin start
    if (document.querySelector('.fixed-plugin')) {
      let fixedPlugin = document.querySelector('.fixed-plugin');
      let fixedPluginButton = document.querySelector('.fixed-plugin-button');
      let fixedPluginButtonNav = document.querySelector(
        '.fixed-plugin-button-nav'
      );
      let fixedPluginCard = document.querySelector('.fixed-plugin .card');
      let fixedPluginCloseButton = document.querySelectorAll(
        '.fixed-plugin-close-button'
      );
      let navbar = document.getElementById('navbarBlur');
      let buttonNavbarFixed = document.getElementById('navbarFixed');

      if (fixedPluginButton) {
        fixedPluginButton.onclick = function () {
          if (!fixedPlugin.classList.contains('show')) {
            fixedPlugin.classList.add('show');
          } else {
            fixedPlugin.classList.remove('show');
          }
        };
      }

      if (fixedPluginButtonNav) {
        fixedPluginButtonNav.onclick = function () {
          if (!fixedPlugin.classList.contains('show')) {
            fixedPlugin.classList.add('show');
          } else {
            fixedPlugin.classList.remove('show');
          }
        };
      }

      fixedPluginCloseButton.forEach(function (el) {
        el.onclick = function () {
          fixedPlugin.classList.remove('show');
        };
      });

      document.querySelector('body').onclick = function (e) {
        if (
          e.target != fixedPluginButton &&
          e.target != fixedPluginButtonNav &&
          e.target.closest('.fixed-plugin .card') != fixedPluginCard
        ) {
          fixedPlugin.classList.remove('show');
        }
      };

      if (navbar) {
        if (navbar.getAttribute('data-scroll') == 'true' && buttonNavbarFixed) {
          buttonNavbarFixed.setAttribute('checked', 'true');
        }
      }
    }
    // Fixed Plugin end

    // Verify navbar blur on scroll
    if (document.getElementById('navbarBlur')) {
      navbarBlurOnScroll('navbarBlur');
    }

    var inputs = document.querySelectorAll('input');

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].hasAttribute('value')) {
        inputs[i].parentElement.classList.add('is-filled');
      }
      inputs[i].addEventListener(
        'focus',
        function (e) {
          this.parentElement.classList.add('is-focused');
        },
        false
      );

      inputs[i].onkeyup = function (e) {
        if (this.value != '') {
          this.parentElement.classList.add('is-filled');
        } else {
          this.parentElement.classList.remove('is-filled');
        }
      };

      inputs[i].addEventListener(
        'focusout',
        function (e) {
          if (this.value != '') {
            this.parentElement.classList.add('is-filled');
          }
          this.parentElement.classList.remove('is-focused');
        },
        false
      );
    }

    // Tabs navigation

    var total = document.querySelectorAll('.nav-pills');

    function initNavs() {
      total.forEach(function (item, i) {
        var moving_div = document.createElement('div');
        var first_li = item.querySelector('li:first-child .nav-link');
        var tab = first_li.cloneNode();
        tab.innerHTML = '-';

        moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
        moving_div.appendChild(tab);
        item.appendChild(moving_div);

        var list_length = item.getElementsByTagName('li').length;

        moving_div.style.padding = '0px';
        moving_div.style.width =
          item.querySelector('li:nth-child(1)').offsetWidth + 'px';
        moving_div.style.transform = 'translate3d(0px, 0px, 0px)';
        moving_div.style.transition = '.5s ease';

        item.onmouseover = function (event) {
          let target = getEventTarget(event);
          let li = target.closest('li'); // get reference
          if (li) {
            let nodes = Array.from(li.closest('ul').children); // get array
            let index = nodes.indexOf(li) + 1;
            item.querySelector(
              'li:nth-child(' + index + ') .nav-link'
            ).onclick = function () {
              moving_div = item.querySelector('.moving-tab');
              let sum = 0;
              if (item.classList.contains('flex-column')) {
                for (var j = 1; j <= nodes.indexOf(li); j++) {
                  sum += item.querySelector(
                    'li:nth-child(' + j + ')'
                  ).offsetHeight;
                }
                moving_div.style.transform =
                  'translate3d(0px,' + sum + 'px, 0px)';
                moving_div.style.height = item.querySelector(
                  'li:nth-child(' + j + ')'
                ).offsetHeight;
              } else {
                for (var j = 1; j <= nodes.indexOf(li); j++) {
                  sum += item.querySelector(
                    'li:nth-child(' + j + ')'
                  ).offsetWidth;
                }
                moving_div.style.transform =
                  'translate3d(' + sum + 'px, 0px, 0px)';
                moving_div.style.width =
                  item.querySelector('li:nth-child(' + index + ')')
                    .offsetWidth + 'px';
              }
            };
          }
        };
      });
    }

    setTimeout(function () {
      initNavs();
    }, 100);
  }

  initMdInput() {
    // Material Design Input function
    var inputs = document.querySelectorAll('input');

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].hasAttribute('value')) {
        inputs[i].parentElement.classList.add('is-filled');
      }
      inputs[i].addEventListener(
        'focus',
        function (e) {
          this.parentElement.classList.add('is-focused');
        },
        false
      );

      inputs[i].onkeyup = function (e) {
        if (this.value != '') {
          this.parentElement.classList.add('is-filled');
        } else {
          this.parentElement.classList.remove('is-filled');
        }
      };

      inputs[i].addEventListener(
        'focusout',
        function (e) {
          if (this.value != '') {
            this.parentElement.classList.add('is-filled');
          }
          this.parentElement.classList.remove('is-focused');
        },
        false
      );
    }
  }

  showEditModal() {
    // console.log('showEditModal : ', 'edit');
    var myModal = new bootstrap.Modal(document.getElementById('edit'), {
      keyboard: false
    });
    myModal.show();
  }

  showModal(modalId) {
    // console.log('showModal : ', modalId);
    var myModal = new bootstrap.Modal(document.getElementById(modalId), {
      keyboard: false
    });
    myModal.show();
  }

  hideModal(modalId) {
    var myModalEl = document.getElementById(modalId);
    var modal = bootstrap.Modal.getInstance(myModalEl);
    // console.log('hideModal : ', modal);

    modal.hide();
  }

  closeAllModals() {
    // close all modals
    /* eslint-disable no-var */
    var modals = document.getElementsByClassName('modal');

    //console.log('modals : ',modals);

    // on every modal change state like in hidden modal
    for (let i = 0; i < modals.length; i++) {
      modals[i].classList.remove('show');
      modals[i].setAttribute('aria-hidden', 'true');
      modals[i].setAttribute('style', 'display: none');
    }
    // get modal backdrops
    let modalsBackdrops = document.getElementsByClassName('modal-backdrop');
    // remove every modal backdrop

    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }
  }

  closeOneModal(modalId) {
    // get modal
    var modal = document.getElementById(modalId);

    // change state like in hidden modal
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');

    // get modal backdrop
    var modalBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove opened modal backdrop
    if (modalBackdrops) {
      for (let i = 0; i < modalsBackdrops.length; i++) {
        document.body.removeChild(modalsBackdrops[i]);
      }
    }
  }
}
