
// 第二層 ROUTER 
// 後台框架 
// 系統管理
let AuthPage = {
  path: '/AuthPage',
  component: () => import('@/views/AuthLayout.vue') ,
  children: [
    {
      path: 'SignIn',
      name: 'SignIn',
      component: () => import('@/views/php/AuthPage/SignIn/SignIn.vue'),
    },
    {
      path: 'FirstLogin',
      name: 'FirstLogin',
      component: () => import('@/views/php/AuthPage/FirstLogin/FirstLogin.vue'),
    },


  ]
};

export default AuthPage;
