/* eslint-disable import/no-unresolved */
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import tw from '@/i18n/zh-TW.json';
import cn from '@/i18n/zh-CN.json';
import en from '@/i18n/en-US.json';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';


import swal from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import '@/assets/css/googleFontMod.css';
import '@/assets/micon/micon.css';
import '@/assets/css/bootstrap.min.css';



// eslint-disable-next-line import/no-unresolved
//import '@/styles/element/index.scss';
// 開發時全部都安裝等要封裝時再來減肥
//import ElementPlus from 'element-plus';
// 只安裝要使用的 元件
//import {ElPagination , ElTabs , ElTransfer , ElSwitch , ElRate} from 'element-plus';a

//import '@/styles/fontawesome6/scss/fontawesome.scss';
//import '@/styles/fontawesome6/scss/solid.scss';
//import '@/styles/fontawesome6/scss/brands.scss';
import '@/styles/fontawesome6/scss/app.scss';
//import '@/styles/oltAdmin/material-dashboard/bootstrap/bootstrap.scss';
import '@/styles/oltAdmin/material-dashboard.scss';
//import '@/styles/kendo-theme-material/scss/all.scss';
//import '@/styles/KendoUi/kendo-material-pink/dist/css/kendo-material-pink.css';
//import '@/styles/KendoUi/default-material/dist/css/default-material.css';
import '@/styles/KendoUi/ntced-material-pink/dist/css/ntced-material-pink.css';

//import '@sweetalert2/theme-material-ui/material-ui.scss';
const swalOptions = {
  confirmButtonColor: '#e91e63',
  //cancelButtonColor: '#333333'
};

const i18n = createI18n({
  legacy: false,
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  messages: {
    'zh-TW': tw,
    'zh-CN': cn,
    'en-US': en
  }
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(swal, swalOptions)
  //.use(ElPagination)
  //.use(ElTabs)
  //.use(ElTransfer)
  //.use(ElSwitch)
  //.use(ElRate)
  //.use(ElementPlus)
  .mount('#app');
