/* eslint-disable prefer-regex-literals */
import { getter } from '@progress/kendo-vue-common';

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
const accountRegex = new RegExp(/^[a-zA-z0-9-_]+$/);
const yearRegex = new RegExp(/^[a-zA-Z0-9]+$/);

export const academicYearValidator = value =>
  value ? '' : '年度為必填欄位!';
export const s1SectionalTestStartValidator1 = value =>
  value ? '' : '上學期第一次段考開始日期為必填欄位!';
export const s1SectionalTestStartValidator2 = value =>
  value ? '' : '上學期第二次段考開始日期為必填欄位!';
export const s1SectionalTestStartValidator3 = value =>
  value ? '' : '上學期第三次段考開始日期為必填欄位!';
export const s1SectionalTestEndValidator1 = value =>
  value ? '' : '上學期第一次段考結束日期為必填欄位!';
export const s1SectionalTestEndValidator2 = value =>
  value ? '' : '上學期第二次段考結束日期為必填欄位!';
export const s1SectionalTestEndValidator3 = value =>
  value ? '' : '上學期第三次段考結束日期為必填欄位!';
export const s2SectionalTestStartValidator1 = value =>
  value ? '' : '下學期第一次段考開始日期為必填欄位!';
export const s2SectionalTestStartValidator2 = value =>
  value ? '' : '下學期第二次段考開始日期為必填欄位!';
export const s2SectionalTestStartValidator3 = value =>
  value ? '' : '下學期第三次段考開始日期為必填欄位!';
export const s2SectionalTestEndValidator1 = value =>
  value ? '' : '下學期第一次段考結束日期為必填欄位!';
export const s2SectionalTestEndValidator2 = value =>
  value ? '' : '下學期第二次段考結束日期為必填欄位!';
export const s2SectionalTestEndValidator3 = value =>
  value ? '' : '下學期第三次段考結束日期為必填欄位!';
export const loginTypeValidator = value =>
  value ? '' : '認證方式為必填欄位!';
export const passwordValidator = value =>
  //value ? '' : '密碼為必填欄位!';
  value && value.length > 6 ? '' : '密碼為必填欄位,且需大於六碼！';
export const passwordSettingValidator = value =>
  //value ? '' : '密碼為必填欄位!';
  !value ?
    '密碼為必填欄位' :
    value.length < 8 ?
      '密碼長度最少 8 個字元!' :
      value.length > 32 ?
        '密碼長度最長 32 個字元!' :
        yearRegex.test(value) ?
          '' :
          '密碼必需為英文大寫小寫或數字！';
export const realNameValidator = value =>
  !value ?
    '姓名為必填欄位' :
    value.length < 2 ?
      '姓名長度最少 2 個字元!' :
      value.length > 6 ?
        '姓名長度最長 6 個字元!' :
        '';
export const nickNameValidator = value =>
  !value ?
    '暱稱為必填欄位' :
    value.length < 2 ?
      '暱稱長度最少 2 個字元!' :
      value.length > 8 ?
        '暱稱長度最長 8 個字元!' :
        '';
export const nameValidator = value =>
  !value ?
    '名稱為必填欄位' :
    value.length > 32 ?
      '名稱長度最長 32 個字元!' :
      '';
export const codeValidator = value =>
  !value ?
    '代碼為必填欄位' :
    value.length > 32 ?
      '代碼長度最長 32 個字元!' :
      '';

export const codeAcademicYearValidator = value =>
  !value ?
    '代碼為必填欄位' :
    value.length > 32 ?
      '代碼長度最長 32 個字元!' :
      yearRegex.test(value) ?
        '' :
        '代碼必需為英文大寫小寫或數字！';

export const accountValidator = value =>
  !value ?
    '帳號為必填欄位！' :
    value.length < 2 ?
      '帳號長度至少需為 2 字元！' : 
      value.length > 32 ?
        '帳號長度最多 32 字元！' : 
        accountRegex.test(value) ?
          '' :
          '帳號格式錯誤，必需為中英大寫小寫！';

export const termsValidator = value =>
  value ? '' : 'It\'s required to agree with Terms and Conditions.';
export const emailValidator = value =>
  !value ?
    'Email 為必填欄位' :
    value.length > 128 ?
      'Email 長度最多 128 字元！' : 
      emailRegex.test(value) ?
        '' :
        'Email 格式錯誤請輸入正確格式!';
export const userNameValidator = value =>
  !value ?
    'User Name is required' :
    value.length < 5 ?
      'User name should be at least 5 characters long.' :
      '';
export const cellPhoneValidator = value =>
  !value ?
    '手機 為必填欄位' :
    value.length < 10 ?
      '手機長度至少需為 10 字元！' :
      value.length > 16 ?
        '手機長度最多 16 字元！' : 
        phoneRegex.test(value) ?
          '' :
          '手機 格式錯誤請輸入正確格式!';
export const phoneValidator= value =>
  !value ?
    '電話 為必填欄位' :
    value.length < 10 ?
      '電話長度至少需為 10 字元！' :
      value.length > 16 ?
        '電話長度最多 16 字元！' :
        '';
export const cardValidator = value =>
  !value ?
    'Credit card number is required. ' :
    ccardRegex.test(value) ?
      '' :
      'Not a valid credit card number format.';
export const cvcValidator = value =>
  !value ?
    'CVC code is required,' :
    cvcRegex.test(value) || value.length !== 3 ?
      '' :
      'Not a valid CVC code format.';
export const guestsValidator = value =>
  !value ?
    'Number of guests is required' :
    value <= 5 ?
      '' :
      'Maximum 5 guests';
export const nightsValidator = value =>
  value ? '' : 'Number of Nights is required.';
export const arrivalDateValidator = value =>
  value ? '' : 'Arrival Date is required.';
export const colorValidator = value => (value ? '' : 'Color is required.');
export const requiredValidator = value =>
  value ? '' : 'Error: This field is required.';
//export const passwordValidator = value =>
  //value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
export const addressValidator = value => (value ? '' : 'Address is required.');

const userNameGetter = getter('username');
const emailGetter = getter('email');

export const formValidator = values => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    'username': !userName ? 'User Name is required.' : '',
    'email':
      emailValue && emailRegex.test(emailValue) ?
        '' :
        'Email is required and should be in a valid format.'
  };
};

export const examTeacherValidator = value =>
  value ? '' : '命題老師為必填欄位!';
export const reviewTimesValidator = value =>
  value ? '' : '審題次數為必填欄位!';
export const reviewTeacherOneValidator = value =>
  !value || value.length == 0 ? '一校審題老師為必填欄位' : value && value.length > 3 ? '一校審題老師不能超過三位': '';
export const reviewTeacherTwoValidator = value =>
  !value || value.length == 0 ? '二校審題老師為必填欄位' : value && value.length > 3 ? '二校審題老師不能超過三位': '';
export const reviewTeacherTwoSecondValidator = value =>
  value != undefined ? '' : '二校審題老師為必填欄位!';
export const reviewTeacherThreeValidator = value =>
  !value || value.length == 0 ? '三校審題老師為必填欄位' : value && value.length > 3 ? '三校審題老師不能超過三位': '';
export const reviewTeacherThreeSecondValidator = value =>
  value != undefined ? '' : '三校審題老師為必填欄位!';
export const learningPhaseValidator = value =>
  value ? '' : '學習階段為必填欄位!';
export const learningPhaseSecondValidator = value =>
  value != undefined ? '' : '學習階段為必填欄位!';
export const fieldValidator = value =>
  value ? '' : '領域為必填欄位!';
export const subjectValidator = value =>
  value ? '' : '科目為必填欄位!';
export const subjectSecondValidator = value =>
  value != undefined ? '' : '科目為必填欄位!';
export const editionValidator = value =>
  value ? '' : '版本為必填欄位!';
export const editionSecondValidator = value =>
  value != undefined ? '' : '版本為必填欄位!';
export const issueIntegrationValidator = value =>
  value && value.length > 0 ? '' : '議題融入為必填欄位!';
export const difficultyLevelValidator = value =>
  value ? '' : '難易度為必填欄位!';
export const homeroom_teacherValidator = value =>
  value && value.length > 0 ? '' : '導師為必填欄位!';

export const classroom_nameValidator = value =>
  value ? '' : '教室名稱為必填欄位!';  
export const ref_nameValidator = value =>
  value ? '' : '班級名稱為必填欄位!';  
export const ref_codeValidator = value =>
  value ? '' : '班級代碼為必填欄位!';  
export const quoteValidator = value =>
  value ? '' : '引用來源為必填欄位!';  