/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { oltConsole } from '@/lib/oltModule/oltConsole';
class oltEncryptModule {
  constructor() {
    oltConsole.log('oltEncrypt constructor');
  }
  test() {
    oltConsole.log('oltEncrypt test');
  }

  storeEncodeText(value) {
    if(!value || value == ""){return "";}
    let str = '';

    try {
      str = btoa(encodeURIComponent(value));
    } catch (e) {
      oltConsole.log('oltEncryptModule storeEncodeText fail. value : ', value);
    }
    return str;
  }

  storeDecodeText(value) {
    if(!value || value == ""){return "";}
    let str = '';

    try {
      str = decodeURIComponent(atob(value));
    } catch (e) {
      oltConsole.log('oltEncryptModule storeDecodeText fail. value : ', value);
    }
    return str;
  }

  storeEncodeObject(value) {
    if(!value || value == ""){return "";}
    let str = '';

    try {
      str = this.storeEncodeText(JSON.stringify(value));
    } catch (e) {
      oltConsole.log(
        'oltEncryptModule storeEncodeObject fail. value : ',
        value
      );
    }
    return str;
  }

  storeDecodeObject(value) {
    if(!value || value == ""){return null;}
    let str = null;

    try {
      str = JSON.parse(this.storeDecodeText(value));
    } catch (e) {
      oltConsole.log(
        'oltEncryptModule storeEncodeObject fail. value : ',
        value
      );
    }
    return str;
  }
}

// eslint-disable-next-line new-cap
const oltEncrypt = new oltEncryptModule();

export { oltEncrypt };
